import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTopStories, getDisplayBlocks } from "../services/sectionService";
import { getVideoAd, getSponsors } from "../services/homePage";
import { AD_PLACEHOLDER } from "../utils/constants/homepageConstants";

const SectionContext = React.createContext({});

const SectionContextProvider = ({ children }) => {
  const [section, setSection] = useState();
  const [town, setTown] = useState();
  const [loading, setLoading] = useState(false);
  const [leftBlocks, setLeftBlocks] = useState([]);
  const [rightBlocks, setRightBlocks] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [videoAd, setVideoAd] = useState(null);
  const [hasLockedBullseyeAd, setHasLockedBullseyeAd] = useState(null);
  const [localNews, setLocalNews] = useState({
    mainStory: undefined,
    secondaryStories: [],
  });

  useEffect(() => {
    const loadVideoAd = async () => {
      const response = await getVideoAd(town.id);
      if (response && response?.status === 200) {
        setVideoAd(response?.data?.video_ad_html);
        setHasLockedBullseyeAd(response?.data?.has_locked_bullseye);
      }
    };

    const loadSponsors = async () => {
      const response = await getSponsors(town.id);
      if (response && response?.status === 200) {
        setSponsors(response?.data?.sponsors);
      }
    };
    if (town) {
      loadVideoAd();
      loadSponsors();
    }
  }, [town]);

  useEffect(() => {
    const loadLocalNews = async () => {
      setLoading(true);
      const response = await getTopStories(town ? town.id : null, section.id);
      if (
        response &&
        response?.status === 200 &&
        response?.data?.top_stories?.length > 0
      ) {
        const topStoriesCount = response.data.top_stories.length;
        const placeholderForAd = { title: AD_PLACEHOLDER, id: 0 };

        setLocalNews({
          mainStory: response.data.top_stories[0],
          secondaryStories:
            topStoriesCount === 1
              ? [placeholderForAd]
              : response.data.top_stories.slice(1, topStoriesCount),
        });
      }
      setLoading(false);
    };
    const loadDisplayBlocks = async () => {
      const response = await getDisplayBlocks(
        town ? town.id : null,
        section.id
      );
      if (response && response?.status === 200) {
        const display_blocks = response?.data?.display_blocks;
        setLeftBlocks(display_blocks.left_blocks);
        setRightBlocks(
          display_blocks.right_blocks ? display_blocks.right_blocks : []
        );
      }
    };

    if (section) {
      loadLocalNews();
      loadDisplayBlocks();
    }
  }, [section]);

  return (
    <SectionContext.Provider
      value={{
        setSection,
        setTown,
        localNews,
        leftBlocks,
        rightBlocks,
        sponsors,
        videoAd,
        town,
        section,
        loading,
        hasLockedBullseyeAd,
      }}
    >
      {children}
    </SectionContext.Provider>
  );
};

SectionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSection = () => {
  const SectionContextValues = useContext(SectionContext);

  if (!SectionContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the SectionContextProvider"
    );
  }

  return SectionContextValues;
};

const withSectionContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <SectionContextProvider>
      <Component {...props} />
    </SectionContextProvider>
  );

  return ComponentWithProvider;
};

export { useSection, withSectionContextProvider };
