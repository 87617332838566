import React from "react";

export const Fog = ({ className, color = 'white' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="32"
    viewBox="0 0 47 32"
    fill="none"
    className={className}
  >
    <path
      d="M41.7364 24.7306C41.9244 25.2006 42.4884 25.3886 42.8644 25.2006C44.9324 24.0726 46.4365 21.6286 46.3425 19.0905C46.2485 16.5525 44.4624 14.3905 41.9244 13.6385C41.9244 13.4505 41.9244 13.2625 41.9244 13.0745C41.9244 9.50244 39.1984 6.30641 35.6264 5.93041C33.9343 5.7424 32.3363 6.11841 31.0203 6.96442C30.3623 2.82838 26.6963 -0.179654 22.2782 0.00834758C18.1422 0.196349 14.8521 3.29838 14.2881 7.15242C13.0661 6.68241 11.7501 6.58841 10.5281 6.87042C7.61408 7.52842 5.45205 10.0664 4.98205 13.0745C4.88805 13.5445 4.88805 13.8265 4.88805 14.2025C2.82003 14.7665 0 15.8945 0 19.9365C0 22.6626 2.06802 25.0126 4.98205 25.5766C5.35805 25.6706 5.64006 25.4826 5.82806 25.2006C6.11006 24.7306 5.82806 24.1666 5.35805 23.9786C3.29003 23.5086 1.69202 21.9106 1.69202 19.9365C1.69202 17.2105 3.19603 16.2705 6.01606 15.6125L6.58007 15.4245V14.3905C6.58007 14.2025 6.48607 14.1085 6.48607 13.9205C6.48607 10.9125 8.93009 8.46843 11.9381 8.46843C12.8781 8.46843 13.7241 8.75044 14.5701 9.22044L15.7922 9.97245V8.56243C15.6982 4.9904 18.6122 1.98237 22.2782 1.79437H22.6542C26.3203 1.79437 29.2343 4.52039 29.4223 8.09243C29.4223 8.18643 29.4223 8.37443 29.4223 8.65643L29.3283 10.8185L30.8323 9.31444C32.0543 8.09243 33.7463 7.43442 35.5324 7.71642C37.6944 7.99843 39.5744 9.69044 40.0444 11.8525C40.2324 12.6985 40.2324 13.4505 40.1384 14.2025L39.9504 15.0485L40.7964 15.2365C42.9584 15.6125 44.4625 17.3045 44.5564 19.2785C44.6505 21.0646 43.6164 22.9446 42.0184 23.8846C41.7364 23.8846 41.6424 24.3546 41.7364 24.7306Z"
      fill={color}
    />
    <path
      d="M33.9343 27.9266C33.9343 28.3966 33.5583 28.8666 32.9943 28.8666C30.7383 28.8666 28.5763 28.8666 26.3203 28.8666C22.2782 28.8666 18.3302 28.8666 14.2881 28.8666C13.6301 28.8666 12.5961 29.1486 12.5961 28.0206C12.5961 26.8926 13.5361 27.0806 14.2881 27.0806C17.7662 27.0806 21.3382 27.0806 24.8162 27.0806C27.5423 27.0806 30.2683 27.0806 33.0883 27.0806C33.5583 26.9866 33.9343 27.3626 33.9343 27.9266Z"
      fill={color}
    />
    <path
      d="M31.2083 17.8685C29.4223 17.8685 27.6363 17.8685 25.8503 17.8685C25.6623 17.8685 25.4743 17.8685 25.1923 17.8685C24.1582 17.8685 23.2182 17.8685 22.1842 17.8685C21.4322 17.8685 20.4922 17.6805 20.4922 18.8085C20.4922 19.9365 21.5262 19.6545 22.1842 19.6545C23.1242 19.6545 24.1582 19.6545 25.0983 19.6545C25.9443 19.6545 26.6963 19.6545 27.5423 19.6545C28.7643 19.6545 29.9863 19.6545 31.1143 19.6545C31.5843 19.6545 32.0543 19.2785 32.0543 18.7145C32.1483 18.2445 31.7723 17.8685 31.2083 17.8685Z"
      fill={color}
    />
    <path
      d="M27.1663 30.1826C25.3803 30.1826 23.5942 30.1826 21.8082 30.1826C21.6202 30.1826 21.4322 30.1826 21.1502 30.1826C20.1162 30.1826 19.1762 30.1826 18.1422 30.1826C17.3902 30.1826 16.4502 29.9946 16.4502 31.1227C16.4502 32.2507 17.4842 31.9687 18.1422 31.9687C19.0822 31.9687 20.1162 31.9687 21.0562 31.9687C21.9022 31.9687 22.6542 31.9687 23.5002 31.9687C24.7222 31.9687 25.9443 31.9687 27.0723 31.9687C27.5423 31.9687 28.0123 31.5927 28.0123 31.0287C28.1063 30.5587 27.7303 30.1826 27.1663 30.1826Z"
      fill={color}
    />
    <path
      d="M36.9424 21.0646C33.0883 21.0646 29.1403 21.0646 25.2863 21.0646C25.1923 21.0646 25.1923 21.0646 25.0983 21.0646C20.5862 21.0646 15.9802 21.0646 11.4681 21.1586H11.3741C10.5281 21.3466 10.5281 22.6626 11.3741 22.8506H11.4681C13.9121 22.9446 16.4502 22.8506 18.8942 22.8506C20.5862 22.8506 23.5942 22.8506 25.0983 22.8506C25.0983 22.8506 25.0983 22.8506 25.1923 22.8506C27.0723 22.8506 28.8583 22.8506 30.7383 22.8506C32.4303 22.8506 35.4384 22.8506 36.9424 22.8506C37.4124 22.8506 37.8824 22.4746 37.8824 21.9106C37.8824 21.4406 37.4124 21.0646 36.9424 21.0646Z"
      fill={color}
    />
    <path
      d="M40.8904 24.9186C40.8904 25.4826 40.4204 25.9526 39.8564 25.9526C35.0624 25.9526 30.3623 25.9526 25.5683 25.9526C20.3042 25.9526 15.1342 25.9526 9.8701 25.9526C9.6821 25.9526 9.49409 25.9526 9.30609 25.9526C8.74209 25.9526 8.17808 25.9526 7.70808 25.9526H7.61408C6.67407 25.7646 6.67407 24.2606 7.61408 24.0726C7.70808 24.0726 7.70808 24.0726 7.80208 24.0726C8.36608 23.9786 8.93009 23.9786 9.4941 23.9786C19.6462 23.9786 29.7983 23.9786 39.9504 23.9786C40.4204 23.9786 40.8904 24.3546 40.8904 24.9186Z"
      fill={color}
    />
  </svg>
);
