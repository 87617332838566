import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  fetchHamburgerMenuItems,
  fetchMastheadInfo,
} from "../services/hamburgerMenu";
import { loadTownsWithClusters } from "../services/townService";
import { useMasthead } from "../hooks/useMasthead";

const MastheadContext = React.createContext({});

const MastheadContextProvider = ({ children }) => {
  const [displayWeather, setDisplayWeather] = useState(null);
  const [hamburgerMenuItems, setHamburgerMenuItems] = useState([]);
  const [adminMenuItems, setAdminMenuItems] = useState([]);
  const [townsWithClusters, setTownsWithClusters] = useState([]);
  const [mastheadInfo, setMastheadInfo] = useState();
  const [initInfo, setInitInfo] = useState({});
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const { sticky } = useMasthead();

  const initialize = (info) => {
    setInitInfo(info);
  };

  useEffect(() => {
    const fetchTownsWithClusters = async (town_slug) => {
      const response = await loadTownsWithClusters(false, town_slug);
      if (response?.status === 200) {
        setTownsWithClusters(response?.data?.towns_with_clusters);
      }
    };

    const fetchMenuItems = async (payload = {}) => {
      const response = await fetchHamburgerMenuItems({
        ...payload,
        current_user_email: initInfo.currentUserEmail,
      });
      if (response?.status === 200) {
        setHamburgerMenuItems(response?.data?.hamburger_menu_items);
        setAdminMenuItems(response?.data?.admin_items);
      }
    };

    const fetchMasthead = async (payload = {}) => {
      const response = await fetchMastheadInfo(payload);
      if (response?.status === 200) {
        setMastheadInfo(response?.data);
      }
    };

    if (initInfo.townSlug) {
      fetchMenuItems({ town_id: initInfo.townSlug });
      fetchMasthead({ town_id: initInfo.townSlug });
      if (townsWithClusters?.length === 0)
        fetchTownsWithClusters(initInfo.townSlug);
    } else if (initInfo.sectionSlug) {
      fetchMenuItems({ section_id: initInfo.sectionSlug });
      fetchMasthead({ section_id: initInfo.sectionSlug });
    } else if (initInfo.overall) {
      fetchMenuItems();
      fetchMasthead();
    }

    return () => {
      setHamburgerMenuItems([]), setAdminMenuItems([]), setMastheadInfo(), setTownsWithClusters([]);
    };
  }, [
    initInfo.townSlug,
    initInfo.sectionSlug,
    initInfo.currentUserEmail,
    initInfo.overall,
  ]);

  useEffect(() => {
    if (openMobileSearch && sticky) {
      setOpenMobileSearch(false);
    }
  }, [sticky, openMobileSearch]);

  const handleOpenMobileSearch = () => {
    setOpenMobileSearch(!openMobileSearch);
  };

  return (
    <MastheadContext.Provider
      value={{
        hamburgerMenuItems,
        adminMenuItems,
        mastheadInfo,
        townsWithClusters,
        initialize,
        handleOpenMobileSearch,
        openMobileSearch,
        displayWeather,
        setDisplayWeather
      }}
    >
      {children}
    </MastheadContext.Provider>
  );
};

MastheadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useMastheadContext = () => {
  const MastheadContextValues = useContext(MastheadContext);

  if (!MastheadContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the MastheadContextProvider"
    );
  }

  return MastheadContextValues;
};

const withMastheadContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <MastheadContextProvider>
      <Component {...props} />
    </MastheadContextProvider>
  );

  return ComponentWithProvider;
};

export { useMastheadContext, withMastheadContextProvider };
