import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../Theme/Theme";
import { AspectRatio } from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.themeColor.primary,
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: `${theme.spacing(2.5)}px !important`,
    },
    horizontal_layout: {
      height: "fit-content",
    },
    horizontal_layout_single_card: {
      height: "100%",
    },
    media_container: {
      width: "38%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
    },
    image: {
      maxWidth: "100%",
    },
    media_container_clickable: {
      width: "100%",
    },
    media_link_one_card: {
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
      width: "60%",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    media_link_one_card_license: {
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
      aspectRatio: "16/9",
      width: "60%",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    media_link: {
      width: "38%",
      maxHeight: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
    },
    media_link_license: {
      aspectRatio: "16/9",
      width: "38%",
      maxHeight: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
    },
    top_section_single_card: {
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    top_section: {
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    height_80_percent: {
      height: "80%",
    },
    information_container: {
      width: "60%",
      display: "flex",
      alignContent: "space-between",
      flexWrap: "wrap",
    },
    information_container_without_media: {
      width: "100%",
    },
    justify_content_to_start: {
      justifyContent: "flex-start",
    },
    media_and_information_container: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    bottom_margin_16_px: {
      marginBottom: theme.spacing(1),
    },
    opacity_on_focus: {
      "&:hover": {
        opacity: "0.8",
      },
    },
  };
});

export { useStyles };
