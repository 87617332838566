import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    palette,
    spacing,
    breakpoints: { down: below, values },
    menuSize,
  } = theme;
  return {
    drawer_root: {
      zIndex: "94036 !important",
    },
    drawer_paper: {
      width: menuSize.default,
      backgroundColor: `#333333 !important`,
      padding: `${spacing(7)}px ${spacing(5)}px !important`,
      paddingRight: "0px !important",
      [below(values.md)]: {
        overflowX: "hidden !important",
        padding: `${spacing(3)}px !important`,
        paddingBottom: `${spacing(10)}px !important`,
        paddingRight: "0px !important",
      },
      [below(values.xs)]: {
        width: menuSize.mobile,
      },
      "& .MuiListItem-divider":{
        borderBottom: "0px !important"
      }
    },
    divider: {
      border: "none !important",
      width: `calc(100% - ${spacing(4)}px)`,
      height: "2px",
      backgroundColor: palette.themeColor.primary,
      transition: "ease 0.3s width",
    },
    arrow: {
      transition: "ease 0.3s transform",
    },
    rotated_arrow: {
      transform: "rotate(90deg)",
      transition: "ease 0.3s transform",
    },
    home_icon: {
      width: spacing(2.5),
      height: spacing(2.5),
      marginRight: spacing(1),
    },
    button: {
      borderRadius: "4px !important",
      padding: `${spacing(1)}px !important`,
      textTransform: "none !important",
      height: "fit-content",
    },
    button_label: {
      fontSize: "16px",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
    },
    menu_item: {
      justifyContent: "space-between !important",
    },
    home_menu_item: {
      gap: spacing(4),
    },
    menu_item_gutters: {
      paddingRight: `${spacing(4)}px !important`,
      paddingLeft: `${spacing(1)}px !important`,
    },
    editor_gutters: {
      padding: "0px !important",
      paddingRight: `${spacing(4)}px !important`,
      paddingBottom: `${spacing(3)}px !important`,
    },
    buttons_list: {
      paddingTop: `${spacing(6)}px !important`,
      paddingRight: `${spacing(4)}px !important`,
      gap: spacing(2),
      display: "flex",
      flexDirection: "column",
      [below(values.md)]: {
        paddingTop: `${spacing(3)}px !important`,
      },
    },
    weather_container: {
      marginBottom: `${spacing(4)}px !important`,
      paddingRight: `${spacing(4)}px !important`,
    },
  };
});

export { useStyles };
