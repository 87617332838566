import React from "react";

export const TStorms = ({ className, color = 'white' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    className={className}
  >
    <path
      d="M35.2163 11.4504C35.2163 11.2893 35.2163 11.1281 35.2163 10.8863C35.2163 7.58229 32.557 4.92294 29.2529 4.92294C28.1247 4.92294 27.0771 5.24528 26.11 5.80939C25.5459 2.42475 22.403 -0.154012 18.6961 0.00716057C15.2308 0.168334 12.4103 2.7471 12.0074 5.97056C11.3627 5.7288 10.718 5.64822 10.0733 5.64822C6.76927 5.64822 4.10992 8.30757 4.10992 11.6116C4.10992 11.6922 4.10992 11.7728 4.10992 11.8534C2.4176 12.3369 0 13.3039 0 16.6886C0 19.2673 2.09525 21.4432 4.99636 21.5238L5.88282 20.1538C5.80223 20.1538 5.64105 20.1538 5.56046 20.1538H5.31871C3.14288 20.1538 1.36997 18.5421 1.36997 16.608C1.36997 14.271 2.65935 13.5457 4.99636 12.9816L5.56046 12.8204V11.934C5.56046 11.7728 5.56046 11.6922 5.56046 11.531C5.56046 8.95227 7.65572 6.93761 10.1539 6.93761C10.8792 6.93761 11.685 7.17936 12.4103 7.58229L13.4579 8.1464V6.93761C13.3774 3.9559 15.795 1.45772 18.9378 1.29655H19.2602C22.3225 1.29655 24.8206 3.63356 24.9818 6.53467C24.9818 6.61526 24.9818 6.77643 24.9818 7.01819L24.9012 8.79109L26.1906 7.50171C27.0771 6.61526 28.2053 6.13174 29.4947 6.13174C32.0734 6.13174 34.0881 8.22699 34.0881 10.7252C34.0881 11.0475 34.0881 11.3699 34.0075 11.6116L33.8463 12.3369L34.5716 12.4981C36.3445 12.8204 37.7145 14.1904 37.7951 15.8827C37.8757 17.575 36.7474 19.5091 34.9745 20.0732L35.4581 21.3626C37.7145 20.6373 39.3262 18.3003 39.2456 15.8021C38.8427 13.868 37.3116 12.0951 35.2163 11.4504Z"
      fill={color}
    />
    <path
      d="M29.1724 22.0878C30.5423 20.2343 30.4618 17.0109 30.5423 17.9779C30.7035 20.7179 31.7511 22.0878 31.7511 22.0878C31.8317 22.249 31.9123 22.4102 31.9123 22.6519C31.9123 23.3772 31.2676 23.9413 30.4618 23.9413C29.6559 23.9413 29.0112 23.3772 29.0112 22.6519C29.0112 22.4102 29.0918 22.249 29.1724 22.0878Z"
      fill={color}
    />
    <path
      d="M24.4176 15.1574C25.5458 13.6263 25.4652 10.9669 25.5458 11.7728C25.707 14.0292 26.5934 15.1574 26.5934 15.1574C26.674 15.3186 26.674 15.4798 26.674 15.6409C26.674 16.205 26.1099 16.6886 25.4652 16.6886C24.8205 16.6886 24.2564 16.205 24.2564 15.6409C24.2564 15.4798 24.337 15.3186 24.4176 15.1574Z"
      fill={color}
    />
    <path
      d="M9.42859 22.0878C8.05862 20.2343 8.13921 17.0109 8.05863 17.9779C7.89745 20.7179 6.84983 22.0878 6.84983 22.0878C6.76924 22.249 6.68866 22.4102 6.68866 22.6519C6.68866 23.3772 7.33335 23.9413 8.13922 23.9413C8.94508 23.9413 9.58976 23.3772 9.58976 22.6519C9.58976 22.4102 9.50918 22.249 9.42859 22.0878Z"
      fill={color}
    />
    <path
      d="M14.1832 15.1574C13.055 13.6263 13.1356 10.9669 13.055 11.7728C12.8938 14.0292 12.0074 15.1574 12.0074 15.1574C11.9268 15.3186 11.9268 15.4797 11.9268 15.6409C11.9268 16.205 12.4909 16.6885 13.1356 16.6885C13.7803 16.6885 14.3444 16.205 14.3444 15.6409C14.3444 15.4797 14.2638 15.3186 14.1832 15.1574Z"
      fill={color}
    />
    <path
      d="M13.6192 32L13.861 31.6777L21.6779 19.5897L14.1027 19.5091L20.5497 9.19402H21.1944L16.8427 18.2197H24.9819L13.6192 32Z"
      fill={color}
    />
  </svg>
);
