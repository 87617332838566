import React from "react";
export const PartlyCloudySnow = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="33"
      viewBox="0 0 42 33"
      fill="none"
    >
      <path
        d="M15.0204 29.2449C12.6531 26.0613 12.8163 20.6735 12.7347 22.3878C12.4082 26.8776 10.6939 29.2449 10.6939 29.2449C10.5306 29.4898 10.449 29.8164 10.449 30.1429C10.449 31.3674 11.5102 32.347 12.898 32.347C14.2041 32.347 15.3469 31.3674 15.3469 30.1429C15.2653 29.898 15.1837 29.5715 15.0204 29.2449Z"
        fill={color}
      />
      <path
        d="M22.5306 25C20.4898 22.2245 20.6531 17.4898 20.4898 19.0409C20.2449 22.9592 18.6939 25 18.6939 25C18.5306 25.2449 18.449 25.4898 18.449 25.8164C18.449 26.8776 19.4286 27.6939 20.5714 27.6939C21.7143 27.6939 22.6939 26.8776 22.6939 25.8164C22.6939 25.4898 22.6122 25.2449 22.5306 25Z"
        fill={color}
      />
      <path
        d="M28.2449 30.5511C26.3673 28.0205 26.5306 23.6939 26.3673 25.0817C26.1224 28.6735 24.7347 30.5511 24.7347 30.5511C24.6531 30.796 24.5714 31.0409 24.5714 31.2858C24.5714 32.2654 25.4694 33 26.5306 33C27.5918 33 28.4898 32.1837 28.4898 31.2858C28.4898 30.9592 28.4082 30.7143 28.2449 30.5511Z"
        fill={color}
      />
      <path
        d="M30.6122 23.1225C29.4694 21.5715 29.551 18.796 29.4694 19.6939C29.3061 21.9796 28.4082 23.1225 28.4082 23.1225C28.3265 23.2858 28.3265 23.449 28.3265 23.6123C28.3265 24.1837 28.898 24.6735 29.551 24.6735C30.2041 24.6735 30.7755 24.1837 30.7755 23.6123C30.6939 23.449 30.6939 23.2858 30.6122 23.1225Z"
        fill={color}
      />
      <path
        d="M40.7347 6.22454C39.5918 2.6327 35.9184 0.510255 32.2449 1.08168C33.7143 1.97964 34.8571 3.3674 35.3469 5.16332C36 7.1225 35.7551 9.08168 34.9388 10.796C34.1224 8.51025 31.9184 6.8776 29.3061 6.8776C28.1633 6.8776 27.102 7.20413 26.1224 7.77556C25.551 4.34699 22.3673 1.81638 18.6939 1.97964C15.1837 2.14291 12.4082 4.75515 12 8.02046C11.3469 7.77556 10.6939 7.69393 10.0408 7.69393C6.77551 7.61229 4.08163 10.3062 4.08163 13.6531C4.08163 13.7347 4.08163 13.8164 4.08163 13.898C2.36735 14.3878 0 15.3674 0 18.796C0 21.3266 2.12245 23.5307 5.06122 23.6939L5.87755 22.2245C5.79592 22.2245 5.63265 22.2245 5.55102 22.2245H5.30612C3.10204 22.2245 1.30612 20.6735 1.30612 18.7143C1.30612 16.4286 2.53061 15.6123 4.89796 15.0409L5.55102 14.8776V14.0613C5.55102 13.898 5.55102 13.8164 5.55102 13.6531C5.55102 11.1225 7.59184 9.00005 10.2041 9.00005C10.9388 9.00005 11.7551 9.24495 12.4082 9.57148L13.4694 10.1429V8.91842C13.3878 5.89801 15.8367 3.3674 18.9388 3.28576H19.2653C22.2857 3.28576 24.8163 5.57148 24.9796 8.59189C24.9796 8.67352 24.9796 8.83678 24.9796 9.08168L24.898 10.8776L26.1224 9.57148C27.0204 8.67352 28.1633 8.18372 29.3878 8.18372C31.7551 8.18372 33.7143 9.97964 33.9592 12.347C33.0612 13.4082 31.9184 14.2245 30.5306 14.7143C30.2041 14.796 29.8775 14.8776 29.4694 14.9592C31.3469 16.1021 33.6327 16.5103 35.9184 15.7756C36.0816 15.6939 36.3265 15.6123 36.4898 15.5307C37.2245 16.1837 37.6326 17 37.7143 17.9796C37.7959 19.6939 36.6531 21.5715 34.8571 22.1429L35.3469 23.449C37.551 22.7143 39.2653 20.4286 39.102 17.898C39.0204 16.7552 38.5306 15.6939 37.7959 14.8776C40.4898 13.0817 41.8776 9.57148 40.7347 6.22454Z"
        fill={color}
      />
    </svg>
  );
};
