import React from "react";
export const PartlyCloudyTStorms = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="33"
      viewBox="0 0 41 33"
      fill="none"
    >
      <path
        d="M29.8927 23.4C31.2195 21.6049 31.1415 18.483 31.2195 19.4196C31.3756 22.0732 32.3902 23.4 32.3902 23.4C32.4683 23.5561 32.5463 23.7122 32.5463 23.9464C32.5463 24.6488 31.922 25.1952 31.1415 25.1952C30.361 25.1952 29.7366 24.6488 29.7366 23.9464C29.7366 23.7903 29.8146 23.5561 29.8927 23.4Z"
        fill={color}
      />
      <path
        d="M25.2878 16.7659C26.3805 15.283 26.3024 12.7074 26.3805 13.4879C26.5366 15.6732 27.3951 16.7659 27.3951 16.7659C27.4732 16.922 27.4732 17.0781 27.4732 17.2342C27.4732 17.7805 26.9268 18.2488 26.3024 18.2488C25.6781 18.2488 25.1317 17.7805 25.1317 17.2342C25.1317 17.0781 25.2098 16.922 25.2878 16.7659Z"
        fill={color}
      />
      <path
        d="M10.7707 23.4C9.4439 21.6049 9.52195 18.483 9.4439 19.4196C9.28781 22.0732 8.27317 23.4 8.27317 23.4C8.19512 23.5561 8.11707 23.7122 8.11707 23.9464C8.11707 24.6488 8.74146 25.1952 9.52195 25.1952C10.3024 25.1952 10.9268 24.6488 10.9268 23.9464C10.9268 23.7903 10.8488 23.5561 10.7707 23.4Z"
        fill={color}
      />
      <path
        d="M15.3756 16.7659C14.2829 15.283 14.361 12.7074 14.2829 13.4879C14.1268 15.6732 13.2683 16.7659 13.2683 16.7659C13.1902 16.922 13.1902 17.0781 13.1902 17.2342C13.1902 17.7805 13.7366 18.2488 14.361 18.2488C14.9854 18.2488 15.5317 17.7805 15.5317 17.2342C15.5317 17.0781 15.4537 16.922 15.3756 16.7659Z"
        fill={color}
      />
      <path
        d="M14.8293 33L15.0634 32.6879L22.6341 20.9805H15.2976L21.5415 10.9903H22.1659L17.8732 19.6537L25.8341 19.7318L14.8293 33Z"
        fill={color}
      />
      <path
        d="M40.2732 6.15127C39.1024 2.56102 35.5122 0.453705 31.8439 1.0781C33.2488 2.01468 34.4195 3.34151 34.9659 5.05858C35.5902 6.93175 35.3561 8.96102 34.5756 10.6C33.7171 8.33663 31.5317 6.69761 29.0341 6.69761C27.9415 6.69761 26.8488 7.0098 25.9122 7.55614C25.3659 4.20005 22.2439 1.62444 18.5756 1.85858C15.1415 2.01468 12.4098 4.59029 11.9415 7.79029C11.3171 7.55614 10.6927 7.4781 9.99024 7.4781C6.7122 7.4781 4.05854 10.1318 4.05854 13.4098C4.05854 13.4879 4.05854 13.5659 4.05854 13.644C2.41951 14.1903 0 15.1269 0 18.561C0 21.0586 2.10732 23.2439 4.99512 23.4L5.85366 22.0732C5.77561 22.0732 5.61951 22.0732 5.54146 22.0732H5.30732C3.12195 22.0732 1.40488 20.5122 1.40488 18.561C1.40488 16.2976 2.65366 15.5171 4.99512 14.9708L5.46341 14.7366V13.8781C5.46341 13.722 5.46341 13.6439 5.46341 13.4879C5.46341 10.9903 7.49268 8.88297 10.0683 8.88297C10.8488 8.88297 11.5512 9.11712 12.2537 9.50736L13.2683 10.1318V8.96102C13.1902 5.99517 15.6098 3.49761 18.6537 3.34151H18.9659C22.0098 3.34151 24.5073 5.60493 24.5854 8.57078C24.5854 8.64883 24.5854 8.80492 24.5854 9.03907L24.5073 10.8342L25.7561 9.58541C26.6146 8.72688 27.7854 8.18053 29.0341 8.18053C31.3756 8.18053 33.3268 9.97566 33.561 12.3171C32.7024 13.3318 31.5317 14.1903 30.1268 14.6586C29.8146 14.7366 29.4244 14.8147 29.1122 14.8927C30.9073 16.0635 33.2488 16.4537 35.4342 15.7513C35.6683 15.6732 35.8244 15.5952 35.9805 15.5171C36.6829 16.1415 37.0732 17 37.1512 17.9366C37.2293 19.5757 36.0585 21.5269 34.3415 22.0732L34.8098 23.4C36.9951 22.6976 38.6341 20.3561 38.5561 17.9366C38.4781 16.7659 38.0098 15.7513 37.3073 14.8927C40.039 12.9415 41.3659 9.42931 40.2732 6.15127Z"
        fill={color}
      />
    </svg>
  );
};
