import React, { Fragment, useState } from "react";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import MenuList from "../../../common/Menu/components/MenuList/MenuList";
import MenuListItem from "../../../common/Menu/components/MenuListItem/MenuListItem";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import { useStyles } from "./SubmenuForMobile.style";
import { checkIfIsNotTheLastItem } from "../../../utils/helper";
import { array, func, object } from "prop-types";
import TriangleArrowForward from "../../../common/Icons/TriangleArrowForward";
import theme from "../../../common/Theme/themes/default";
import {
  itemHasSubmenu,
  isSelectedMenuItem,
} from "../../../services/hamburgerMenu";

const TOP_TARGET = "_top";

const SubmenuForMobile = ({ subMenuItems, typographyProps }) => {
  const classes = useStyles();
  const [selectedItemId, setSelectedItemId] = useState();

  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;

  const handleSubMenuItemClick = (item) => {
    if (selectedItemId === item.id) {
      setSelectedItemId();
    } else {
      setSelectedItemId(item.id);
    }
  };

  const handleItemClick = (item) => {
    window.open(item.path, TOP_TARGET);
  };

  return (
    <MenuList disablePadding={true}>
      {subMenuItems?.map((submenuItem, submenuIndex) => (
        <Fragment key={`submenu-list-${submenuIndex}`}>
          {itemHasSubmenu(submenuItem) > 0 ? (
            <>
              <MenuListItem
                title={submenuItem.name}
                key={`submenu-list-item-${submenuIndex}`}
                disableGutters={false}
                classes={{
                  root: classes.submenu_list_item,
                  gutters: classes.submenu_item_gutters,
                }}
                onClick={() => handleSubMenuItemClick(submenuItem)}
              >
                <div className={classes.section_item}>
                  <Typography
                    level={typographyProps?.level || "paragraph_2"}
                    color={typographyProps?.color || "white"}
                    bold={typographyProps?.bold || false}
                    underlined={typographyProps?.underlined || false}
                  >
                    {submenuItem.name}
                  </Typography>
                  {submenuItem?.submenu_items.length > 0 && (
                    <TriangleArrowForward
                      className={clsx(
                        isSelectedMenuItem(selectedItemId, submenuItem) &&
                          classes.rotated_arrow,
                        classes.arrow
                      )}
                      fillColor={"#8D8D8D"}
                    />
                  )}
                </div>
                {checkIfIsNotTheLastItem(submenuIndex, subMenuItems) && (
                  <HorizontalDivider className={classes.submenu_divider} />
                )}
              </MenuListItem>
              {isSelectedMenuItem(selectedItemId, submenuItem) && (
                <>
                  {submenuItem.submenu_items.map((subsection, index) => {
                    return (
                      <MenuListItem
                        title={subsection.name}
                        key={`submenu-list-item-${index}`}
                        disableGutters={false}
                        classes={{
                          root: classes.subsection_list_item,
                          gutters: classes.submenu_item_gutters,
                        }}
                        onClick={() => handleItemClick(subsection)}
                      >
                        <Typography
                          level={typographyProps?.level || "paragraph_2"}
                          color={typographyProps?.color || "white"}
                          bold={typographyProps?.bold || false}
                          underlined={typographyProps?.underlined || false}
                        >
                          {subsection.name}
                        </Typography>
                      </MenuListItem>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <MenuListItem
              title={submenuItem.name}
              key={`submenu-list-item-${submenuIndex}`}
              disableGutters={false}
              classes={{
                root: classes.submenu_list_item,
                gutters: classes.submenu_item_gutters,
              }}
              onClick={() => handleItemClick(submenuItem)}
            >
              <Typography
                level={typographyProps?.level || "paragraph_2"}
                color={typographyProps?.color || "white"}
                bold={typographyProps?.bold || false}
                underlined={typographyProps?.underlined || false}
              >
                {submenuItem.name}
              </Typography>

              {checkIfIsNotTheLastItem(submenuIndex, subMenuItems) && (
                <HorizontalDivider className={classes.submenu_divider} />
              )}
            </MenuListItem>
          )}
        </Fragment>
      ))}
      <HorizontalDivider
        className={clsx(classes.divider, classes.full_width_divider)}
      />
    </MenuList>
  );
};

SubmenuForMobile.propTypes = {
  subMenuItems: array,
  onItemClick: func,
  typographyProps: object,
};

export default SubmenuForMobile;
