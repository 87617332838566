const legalNoticesLabels = {
  HEADER_INDEX_NAME: "LEGAL NOTICES",
  SUBMIT_LEGAL_NOTICE: "Submit a Legal Notice",
  LOAD_MORE_LEGAL_NOTICES: "See more legal notices",
  BACK_LINK: "BACK TO LEGAL NOTICES",
  SOCIAL_NETWORK_SHARE: "Share this Legal Notice",
  DESCRIPTION: "DESCRIPTION",
  PUBLISH: "Publication date: ",
  REPUBLISHED: "Second Publication date: ",
  SECOND_REPUBLISHED: "Third Publication date: ",
  ADDRESS: "Address: ",
  PHONE: "Phone: ",
  EMAIL: "Email: ",
  PDF_DOWNLOAD: "Download PDF",
  PRINT_LEGAL_NOTICE: "PRINT",
  SUBMIT_REQUEST_LEGAL_NOTICE: "Create an Entity",
  REQUEST_PAY_BY_PO: "I would like to request authorization to pay by PO.",
  ADD_MORE_ENTITIES: "Create more entities",
  CANCEL_REQUEST: "Cancel",
  DRAG_AND_DROP_LOGO_ENTITY_INSTRUCTIONS:
    "Drag and drop a file here, or click Browse files to select from your computer",
  BROWSE_FILES: "BROWSE FILES",
  LEGAL_NOTICE_REQUEST_THANK_YOU: "Thank You!",
  OK: "OK",
  SAVE_ENTITY: "Create Entity",
  CHOOSE_A_LEGAL_NOTICE_ENTITY: "Choose a legal notice entity *",
  CREATE_A_LEGAL_NOTICE_ENTITY: "Create a legal notice entity *",
  DISPLAY_ENTITY_LOGO: "Display Entity Logo on Carousel",
  DEFAULT_PHOTO:
    "Uncheck if you'd like to upload your own photo. Otherwise, this stock image will be used.",
  ADD_MORE_DATES: "+ Add more dates",
  SCHEDULE_PUBLICATION_DATE: "Schedule publication *",
  SUBMIT_A_LEGAL_NOTICE: "Submit a Legal Notice",
  EDIT_LEGAL_NOTICE: "Edit Legal Notice",
  LEGAL_NOTICE_NAME: "Legal notice headline ",
  LOGO_ENTITY_LABEL: "Upload Entity Logo (optional)",
  THANK_YOU_MESSAGE_FOR_CREATING_LEGAL_NOTICE_ENTITY:
    "You will get an email notification within the next 72 hours letting you know if your request was approved or denied. If you don´t receive an email , please contact us at:",
  ENTITY_LOGO_ALT_TEXT: " - Legal Notice Entity Logo",
  CREATE_NEW_ENTITY: "Create a new entity",
  ENTITY_NAME: "Entity Name: ",
  DOWNLOAD_LEGAL_NOTICE: "Download Legal Notice",
  ENTITY_VISIBILITY_WARNING:
    "The entity data entered will be visible to everyone",
  SELECT_TOWNS: "Select Towns",
  SUBMITTED_AT: "Submitted at: ",
};

export default legalNoticesLabels;
