import React from "react";
export const MostlyCloudySnowNight = ({color = 'white'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="33"
    viewBox="0 0 43 33"
    fill="none"
  >
    <path
      d="M11.2593 26.4239H11.9365V25.8313H11.2593V26.4239ZM13.7989 27.2705L14.2222 27.6937L14.6455 27.2705L14.2222 26.8472L13.7989 27.2705ZM10.328 23.0376L9.90476 23.4609L10.328 23.8842L10.7513 23.4609L10.328 23.0376ZM16.5079 26.4239H17.1852V25.8313H16.5079V26.4239ZM11.8519 20.4133H11.1746V21.0906H11.8519V20.4133ZM17.2698 20.4133H16.5926V21.0906H17.2698V20.4133ZM14.6455 19.5667L14.2222 19.1435L13.7989 19.5667L14.2222 19.99L14.6455 19.5667ZM17.8624 24.3921L17.4392 23.3763L17.8624 22.4451L17.1005 23.207H15.9153L16.3386 22.5297L15.5767 22.3604L16.4233 21.5138H17.5238L16.5079 21.1752L16.0847 20.244V21.3445L15.2381 22.1911L15.0688 21.3445L14.3915 21.8525V20.6673L15.1534 19.8207L14.2222 20.3287L13.291 19.9054L14.0529 20.6673V21.8525L13.3757 21.4292L13.291 22.1911L12.4444 21.3445V20.244L12.1058 21.2599L11.0053 21.5985H12.1058L12.9524 22.4451L12.0212 22.6144L12.5291 23.2916H11.2593L10.4974 22.5297L10.836 23.4609L10.4974 24.3921L11.2593 23.5456H12.5291L12.0212 24.2228L12.9524 24.3921L12.1058 25.2387H11.0053L12.0212 25.5773L12.3598 26.5085V25.408L13.2063 24.5615L13.3757 25.408L14.0529 24.9001V26.1699L13.291 26.9318L14.2222 26.5085L15.1534 26.9318L14.3915 26.3392V25.0694L15.0688 25.5773L15.2381 24.7308L16.0847 25.5773V26.6779L16.4233 25.7466L17.4392 25.408H16.3386L15.4921 24.5615L16.3386 24.3075L15.8307 23.6302H17.0159L17.8624 24.3921ZM13.291 22.7837L13.7989 23.2916H13.1217L13.291 22.7837ZM13.291 24.1382L13.037 23.6302H13.7143L13.291 24.1382ZM14.0529 24.5615L13.545 24.3921L14.0529 23.8842V24.5615ZM14.0529 23.0376L13.545 22.5297L14.0529 22.3604V23.0376ZM15.1534 22.7837L15.3228 23.2916H14.6455L15.1534 22.7837ZM14.3915 22.3604L14.8995 22.5297L14.3915 23.0376V22.3604ZM14.3915 24.5615V23.7996L14.8995 24.3075L14.3915 24.5615ZM15.1534 24.1382L14.6455 23.6302H15.4074L15.1534 24.1382ZM18.0317 23.0376L17.6085 23.4609L18.0317 23.8842L18.455 23.4609L18.0317 23.0376Z"
      fill={color}
    />
    <path
      d="M28.9524 20.0747H29.291V19.7361H28.9524V20.0747ZM30.1376 20.498L30.3915 20.7519L30.6455 20.498L30.3915 20.244L30.1376 20.498ZM28.4444 18.3816L28.1905 18.6355L28.4444 18.8895L28.6984 18.6355L28.4444 18.3816ZM31.5767 20.0747H31.9153V19.7361H31.5767V20.0747ZM29.2063 17.1117H28.8677V17.4503H29.2063V17.1117ZM31.9153 17.1117H31.5767V17.4503H31.9153V17.1117ZM30.6455 16.6038L30.3915 16.3498L30.1376 16.6038L30.3915 16.8578L30.6455 16.6038ZM32.1693 19.0588L32 18.5509L32.1693 18.0429L31.8307 18.4662H31.2381L31.4921 18.1276L31.0688 18.0429L31.4921 17.6197H32L31.4921 17.4503L31.3228 16.9424V17.4503L30.8995 17.8736L30.8148 17.4503L30.4762 17.789V17.1964L30.8995 16.7731L30.4762 17.0271L29.9683 16.8578L30.3915 17.281V17.789L30.0529 17.535L29.9683 17.9583L29.545 17.535V16.9424L29.3757 17.4503L28.7831 17.6197H29.291L29.7143 18.0429L29.291 18.1276L29.545 18.4662H28.9524L28.5291 18.0429L28.6984 18.5509L28.5291 19.0588L28.9524 18.6355H29.6296L29.3757 18.9742L29.7989 19.0588L29.291 19.4821H28.7831L29.291 19.6514L29.4603 20.1593V19.6514L29.8836 19.2281L29.9683 19.6514L30.3069 19.3974V19.99L29.8836 20.3287L30.3915 20.1593L30.8148 20.3287L30.4762 19.99V19.3974L30.8148 19.6514L30.8995 19.2281L31.3228 19.6514V20.1593L31.4921 19.6514L32 19.4821H31.4921L31.0688 19.0588L31.4921 18.9742L31.2381 18.6355H31.8307L32.1693 19.0588ZM29.9683 18.2123L30.2222 18.4662H29.8836L29.9683 18.2123ZM29.9683 18.8895L29.7989 18.6355H30.1376L29.9683 18.8895ZM30.3069 19.1435L30.0529 19.0588L30.3069 18.8048V19.1435ZM30.3069 18.3816L30.0529 18.1276L30.3069 18.0429V18.3816ZM30.8995 18.2123L30.9841 18.4662H30.6455L30.8995 18.2123ZM30.4762 18.0429L30.7302 18.1276L30.4762 18.3816V18.0429ZM30.4762 19.1435V18.8048L30.7302 19.0588L30.4762 19.1435ZM30.8148 18.8895L30.5608 18.6355H30.8995L30.8148 18.8895ZM32.3386 18.3816L32.0847 18.6355L32.3386 18.8895L32.5926 18.6355L32.3386 18.3816Z"
      fill={color}
    />
    <path
      d="M20.2328 31.4186H20.5714V31.08H20.2328V31.4186ZM21.418 31.8419L21.672 32.0959L21.9259 31.8419L21.672 31.5879L21.418 31.8419ZM19.7249 29.6408L19.4709 29.8948L19.7249 30.1488L19.9788 29.8948L19.7249 29.6408ZM22.8571 31.4186H23.1958V31.08H22.8571V31.4186ZM20.4868 28.371H20.1481V28.7096H20.4868V28.371ZM23.1958 28.371H22.8571V28.7096H23.1958V28.371ZM21.9259 27.9477L21.672 27.6937L21.418 27.9477L21.672 28.2017L21.9259 27.9477ZM23.4497 30.4027L23.2804 29.8948L23.4497 29.3869L23.1111 29.8101H22.5185L22.7725 29.4715L22.3492 29.3869L22.7725 28.9636H23.2804L22.7725 28.7943L22.6032 28.2863V28.7943L22.1799 29.2175L22.0952 28.7943L21.7566 29.0482V28.4556L22.1799 28.0324L21.7566 28.2863L21.2487 28.117L21.672 28.5403V29.1329L21.3333 28.8789L21.164 29.3022L20.7407 28.7943V28.2863L20.5714 28.7943L20.0635 28.9636H20.5714L20.9947 29.3869L20.5714 29.4715L20.8254 29.8101H20.2328L19.8095 29.3869L19.9788 29.8948L19.8095 30.4027L20.2328 29.9794H20.8254L20.5714 30.3181L20.9947 30.4027L20.5714 30.826H20.0635L20.5714 30.9953L20.7407 31.5033V30.9953L21.164 30.572L21.2487 30.9953L21.5873 30.7414V31.3339L21.164 31.6726L21.672 31.5033L22.0952 31.6726L21.7566 31.3339V30.7414L22.0952 30.9953L22.1799 30.572L22.6032 30.9953V31.5033L22.7725 30.9953L23.2804 30.826H22.7725L22.3492 30.4027L22.7725 30.3181L22.5185 29.9794H23.1111L23.4497 30.4027ZM21.2487 29.5562L21.5026 29.8101H21.164L21.2487 29.5562ZM21.2487 30.2334L21.164 29.9794H21.5026L21.2487 30.2334ZM21.5873 30.4874L21.3333 30.4027L21.5873 30.1488V30.4874ZM21.5873 29.7255L21.3333 29.4715L21.5873 29.3869V29.7255ZM22.1799 29.5562L22.2645 29.8101H21.9259L22.1799 29.5562ZM21.7566 29.3022L22.0106 29.3869L21.7566 29.6408V29.3022ZM21.7566 30.4874V30.1488L22.0106 30.4027L21.7566 30.4874ZM22.1799 30.2334L21.9259 29.9794H22.2645L22.1799 30.2334ZM23.619 29.6408L23.3651 29.8948L23.619 30.1488L23.873 29.8948L23.619 29.6408Z"
      fill={color}
    />
    <path
      d="M20.3175 21.5138H20.8254V21.0059H20.3175V21.5138ZM22.1799 22.1064L22.5185 22.4451L22.8571 22.1064L22.5185 21.7678L22.1799 22.1064ZM19.6402 18.9742L19.3016 19.3128L19.6402 19.6514L19.9788 19.3128L19.6402 18.9742ZM24.2963 21.5138H24.8042V21.0059H24.2963V21.5138ZM20.3175 17.0271V17.535H20.8254V17.0271H20.3175ZM24.8042 17.0271H24.2963V17.535H24.8042V17.0271ZM22.8571 16.3498L22.5185 16.0112L22.1799 16.3498L22.5185 16.6884L22.8571 16.3498ZM25.2275 19.99L24.8889 19.2281L25.2275 18.5509L24.6349 19.1435H23.7037L24.0423 18.6355L23.4497 18.5509L24.127 17.8736H24.8889L24.127 17.6197L23.873 16.8578V17.7043L23.1958 18.3816L23.1111 17.789L22.6032 18.1276V17.1964L23.1958 16.6038L22.5185 16.9424L21.7566 16.6038L22.3492 17.1964V18.1276L21.8413 17.789L21.7566 18.3816L21.164 17.7043V16.8578L20.9101 17.6197L20.1481 17.8736H20.9947L21.672 18.5509L20.9101 18.6355L21.3333 19.1435H20.4021L19.8095 18.5509L20.0635 19.3128L19.8095 19.99L20.4021 19.3974H21.3333L20.9101 19.9054L21.5873 19.99L20.9101 20.6673H20.0635L20.8254 20.9212L21.0794 21.6832V20.9212L21.672 20.3287L21.7566 21.0059L22.2645 20.5826V21.5138L21.672 22.1064L22.3492 21.7678L23.0265 22.1064L22.4339 21.5138V20.5826L22.9418 20.9212L23.0265 20.3287L23.7037 21.0059V21.7678L23.9577 21.0906L24.7196 20.8366H23.9577L23.2804 20.244L23.873 20.0747L23.5344 19.5667H24.4656L25.2275 19.99ZM21.8413 18.8048L22.2645 19.1435H21.7566L21.8413 18.8048ZM21.8413 19.8207L21.672 19.3974H22.1799L21.8413 19.8207ZM22.4339 20.0747L22.0952 19.9054L22.4339 19.4821V20.0747ZM22.4339 18.9742L22.0952 18.6355L22.4339 18.4662V18.9742ZM23.1958 18.8048L23.3651 19.1435H22.8571L23.1958 18.8048ZM22.6878 18.4662L23.0265 18.6355L22.6878 18.9742V18.4662ZM22.6878 20.1593V19.5667L23.0265 19.9054L22.6878 20.1593ZM23.1958 19.8207L22.8571 19.3974H23.4497L23.1958 19.8207ZM25.3968 18.8895L25.0582 19.2281L25.3968 19.5667L25.7354 19.2281L25.3968 18.8895Z"
      fill={color}
    />
    <path
      d="M27.3439 27.2705H27.8519V26.7625H27.3439V27.2705ZM29.2064 27.863L29.545 28.2017L29.8836 27.863L29.545 27.5244L29.2064 27.863ZM26.6667 24.7308L26.328 25.0694L26.6667 25.408L27.0053 25.0694L26.6667 24.7308ZM31.3228 27.2705H31.8307V26.7625H31.3228V27.2705ZM27.7672 22.7837H27.2593V23.2916H27.7672V22.7837ZM31.8307 22.7837H31.3228V23.2916H31.8307V22.7837ZM29.8836 22.1064L29.545 21.7678L29.2064 22.1064L29.545 22.4451L29.8836 22.1064ZM32.254 25.7466L31.9153 24.9847L32.254 24.3075L31.6614 24.9001H30.7302L31.0688 24.3921L30.4762 24.3075L31.1534 23.6302H31.9153L31.1534 23.3763L30.8995 22.6144V23.4609L30.2222 24.1382L30.1376 23.5456L29.6296 23.8842V22.953L30.2222 22.3604L29.545 22.699L28.7831 22.3604L29.3757 22.953V23.8842L28.8677 23.5456L28.7831 24.0535L28.1058 23.3763V22.5297L27.9365 23.3763L27.1746 23.6302H28.0212L28.6984 24.3075L27.9365 24.3921L28.3598 24.9001H27.4286L26.836 24.3075L27.0899 25.0694L26.836 25.7466L27.4286 25.1541H28.3598L27.9365 25.662L28.6138 25.7466L27.9365 26.4239H27.0899L27.8519 26.6779L28.1058 27.4398V26.6779L28.6984 26.0853L28.7831 26.7625L29.291 26.3392V27.2705L28.6984 27.863L29.3757 27.5244L30.0529 27.863L29.4603 27.2705V26.2546L29.9683 26.5932L30.0529 26.0006L30.7302 26.6779V27.4398L30.9841 26.7625L31.746 26.5085H30.9841L30.4762 25.8313L31.0688 25.662L30.7302 25.1541H31.6614L32.254 25.7466ZM28.8677 24.5615L29.291 24.9001H28.7831L28.8677 24.5615ZM28.8677 25.5773L28.6984 25.1541H29.2064L28.8677 25.5773ZM29.4603 25.8313L29.1217 25.662L29.4603 25.2387V25.8313ZM29.4603 24.7308L29.1217 24.3921L29.4603 24.2228V24.7308ZM30.2222 24.5615L30.3915 24.9001H29.8836L30.2222 24.5615ZM29.7143 24.2228L30.0529 24.3921L29.7143 24.7308V24.2228ZM29.7143 25.916V25.3234L30.0529 25.662L29.7143 25.916ZM30.2222 25.5773L29.8836 25.1541H30.4762L30.2222 25.5773ZM32.4233 24.6461L32.0847 24.9847L32.4233 25.3234L32.7619 24.9847L32.4233 24.6461Z"
      fill={color}
    />
    <path
      d="M42.2434 5.42918C41.0582 1.70431 37.2487 -0.496744 33.4392 0.0958487C34.963 1.02707 36.1481 2.46622 36.6561 4.32865C37.3333 6.3604 37.0794 8.39214 36.2328 10.1699C35.3862 7.79955 33.1005 6.10643 30.3915 6.10643C29.2064 6.10643 28.1058 6.44505 27.0899 7.03765C26.4974 3.48209 23.1958 0.857753 19.3862 1.02707C15.746 1.19638 12.8677 3.90537 12.4444 7.29162C11.7672 7.03765 11.0899 6.95299 10.4127 6.95299C7.02646 6.86833 4.2328 9.66199 4.2328 13.1329C4.2328 13.2175 4.2328 13.3022 4.2328 13.3869C2.45503 13.8948 0 14.9107 0 18.4662C0 21.0906 2.20106 23.3763 5.24868 23.5456L6.09524 22.0218C6.01058 22.0218 5.84127 22.0218 5.75661 22.0218H5.50265C3.21693 22.0218 1.3545 20.4133 1.3545 18.3816C1.3545 16.0112 2.62434 15.1646 5.07936 14.572L5.75661 14.4027V13.5562C5.75661 13.3869 5.75661 13.3022 5.75661 13.1329C5.75661 10.5085 7.87302 8.30749 10.582 8.30749C11.3439 8.30749 12.1905 8.56146 12.8677 8.90008L13.9683 9.49267V8.22283C13.8836 5.09056 16.4233 2.46622 19.6402 2.38156H19.9788C23.1111 2.38156 25.7355 4.75193 25.9048 7.88421C25.9048 7.96886 25.9048 8.13818 25.9048 8.39214L25.8201 10.2546L27.0899 8.90008C28.0212 7.96886 29.2063 7.46093 30.4762 7.46093C32.9312 7.46093 34.963 9.32336 35.2169 11.7784C34.2857 12.8789 33.1005 13.7255 31.6614 14.2334C31.3228 14.3181 30.9841 14.4027 30.5608 14.4874C32.5079 15.6726 34.8783 16.0958 37.2487 15.3339C37.418 15.2493 37.672 15.1646 37.8413 15.08C38.6032 15.7572 39.0265 16.6038 39.1111 17.6197C39.1958 19.3974 38.0106 21.3445 36.1481 21.9371L36.6561 23.2916C38.9418 22.5297 40.7196 20.1593 40.5503 17.535C40.4656 16.3498 39.9577 15.2493 39.1958 14.4027C41.9894 12.5403 43.4286 8.90008 42.2434 5.42918Z"
      fill={color}
    />
  </svg>
);
