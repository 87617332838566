import React, { useMemo } from "react";
import Grid from "../../../../../common/Grid/Grid";
import { AD_PLACEHOLDER } from "../../../../../utils/constants/homepageConstants";
import VerticalCard from "../../VerticalCard/VerticalCard";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import VideoAd from "../../../../Ad/VideoAd";
import AdCard from "../../../../Homepage/AdCard/AdCard";
import { useStyles } from "./Layout3.style";

const Layout3 = ({ adTargets, videoAd, localNews, hasLockedBullseyeAd }) => {
  const classes = useStyles();
  const isMediumScreen = screenWidthIsLowerThan(1025);
  const isSmallScreen = screenWidthIsLowerThan(values.sm);
  const { mainStory, secondaryStories } = localNews || {};
  const stories = useMemo(() => {
    let mergedStories = mainStory
      ? [mainStory, ...(secondaryStories?.length > 0 ? secondaryStories : [])]
      : [];
    const adPlaceholder = { title: AD_PLACEHOLDER, id: 0 };
    if (mergedStories?.length > 0) {
      mergedStories.splice(2, 0, adPlaceholder);
    }
    return mergedStories;
  }, [mainStory, secondaryStories]);
  const cardWidth = useMemo(() => {
    if (isSmallScreen) {
      return 12;
    }
    if (isMediumScreen) {
      return 6;
    }
    return 4;
  }, [isMediumScreen, isSmallScreen]);

  if (hasLockedBullseyeAd === null) return null;

  return (
    <Grid
      container
      spacing={isSmallScreen ? 0 : 2}
      className={classes.main_container_layout_3}
    >
      {stories.map((story) =>
        story?.title === AD_PLACEHOLDER ? (
          <Grid
            key="top-stories-ad"
            item
            xs={cardWidth}
            classes={{ root: classes.ad_container_layout_3 }}
          >
            {videoAd && Object.keys(videoAd).length > 0 ? (
              <VideoAd videoAd={videoAd} />
            ) : (
              <AdCard
                adTargets={adTargets}
                adSize={[300, 250]}
                adUnit={
                  hasLockedBullseyeAd ? "locked_bullseye" : "tap_bullseye_001"
                }
                options={{
                  forTopStories: true,
                }}
              />
            )}
          </Grid>
        ) : (
          <Grid key={story?.id} item xs={cardWidth}>
            <VerticalCard variant="layout_3" contents={story} />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Layout3;
