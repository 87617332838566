import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  arrow: {
    transition: "ease 0.3s transform",
  },
  rotated_arrow: {
    transform: "rotate(90deg)",
    transition: "ease 0.3s transform",
  },
  divider: {
    border: "none",
    height: "2px",
    backgroundColor: theme.palette.themeColor.primary,
    width: `calc(100% - ${theme.spacing(4)}px)`,
    borderRadius: "8px",
    margin: "0px",
  },
  full_width_divider: {
    width: `calc(100% - ${theme.spacing(4)}px)`,
    marginTop: theme.spacing(1),
  },
  section_item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  submenu_list_item: {
    flexDirection: "column",
    alignItems: "flex-start !important",
    gap: theme.spacing(1),
  },
  section_list_item: {
    margin: `${theme.spacing(1)}px ${theme.spacing(4)}px 0px ${theme.spacing(
      4
    )}px`,
  },
  subsection_list_item: {
    flexDirection: "column",
    alignItems: "flex-start !important",
    gap: theme.spacing(1),
    "& span": {
      fontWeight: "400",
      paddingLeft: `${theme.spacing(2)}px`,
      fontStyle: "italic",
    },
  },
  submenu_divider: {
    border: "none",
    height: "2px",
    backgroundColor: theme.palette.dark.gray,
    width: "100%",
  },
  submenu_divider_section: {
    border: "none",
    height: "2px",
    backgroundColor: theme.palette.dark.gray,
    width: "100%",
    marginTop: `${theme.spacing(1)}px`,
  },
  submenu_item_gutters: {
    paddingRight: `${theme.spacing(4)}px !important`,
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingBottom: "0px !important",
  },
}));

export { useStyles };
