import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BlockTitle from "../../../Shared/BlockTitle/BlockTitle";
import { defineTypeOfContentForDisplayBlocks } from "../../../../../utils/helpers/homepageHelper";
import { useStyles } from "./ContentTitle.style";
import { useTown } from "../../../../../context/TownContext";
import Sponsor from "../../../../Sponsor/Sponsor";
import clsx from "clsx";

const ContentTitle = ({
  sponsorInfo,
  block,
  isOneCard,
  isCustom = null,
  customURL = null,
}) => {
  const classes = useStyles();
  const { town } = useTown();
  const [haveSponsors, setHaveSponsors] = useState(false);
  useEffect(() => {
    setHaveSponsors(sponsorInfo);
  }, [sponsorInfo]);
  return (
    <div
      className={clsx(
        isOneCard && !haveSponsors
          ? classes.title_one_card_no_sponsor
          : classes.title_container,
        !haveSponsors
          ? classes.title_container_no_sponsor
          : classes.title_container
      )}
    >
      <BlockTitle
        options={{ underlined: false }}
        title={block.title.toUpperCase()}
        townSlug={town?.slug}
        isCustom={isCustom}
        customURL={customURL}
        contentType={defineTypeOfContentForDisplayBlocks({
          blockClass: block.class,
          slug: block.slug,
        })}
      />

      {sponsorInfo && (
        <div
          id={block?.class + "-" + block?.id}
          className={classes.sponsor_container}
        >
          <Sponsor sponsor_info={sponsorInfo} />
        </div>
      )}
    </div>
  );
};

ContentTitle.propTypes = {
  block: PropTypes.object.isRequired,
  sponsorInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  isOneCard: PropTypes.bool,
};
ContentTitle.defaultProps = {
  isOneCard: false,
};

export default ContentTitle;
