import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, up: above, values },
  } = theme;
  return {
    search_text_field: {
      borderRadius: "4px",
      width: "50% !important",
      height: spacing(4),
      "& input.MuiOutlinedInput-input": {
        fontSize: "12px !important",
      },
      [below(values.md)]: {
        flex: 5,
      },
    },
    textfield_search_icon: {
      width: spacing(1.5),
      height: spacing(1.5),
    },
    submit_button: {
      height: `${spacing(4)}px !important`,
      textTransform: "uppercase !important",
      margin: "0px !important",
      padding: "0px 20px !important",
      width: "fit-content !important",
      "& .MuiButton-label": {
        fontWeight: "500 !important",
      },
    },
    reduced_masterhead: {
      height: spacing(8.5),
      display: "flex",
      [below(values.md)]: {
        zIndex: 1300,
      },
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    grid_item: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      gap: spacing(1),
      height: "100%",
      [below(values.md)]: {
        gap: 0,
      },
    },
    signup: {
      width: "fit-content !important",
      margin: "0px !important",
      height: "fit-content !important",
      textTransform: "uppercase !important",
      padding: `${spacing(1)}px !important`,
      "& .MuiButton-label": {
        fontWeight: "500 !important",
        fontSize: "12px !important",
      },
      [above(values.lg)]: {
        padding: `${spacing(1)}px ${spacing(3)}px !important`,
      },
    },
    button_icon: {
      padding: "0px !important",
      "& svg": {
        display: "block",
        width: spacing(2),
        height: spacing(2),
      },
    },
    mobile_menu_container: {
      justifyContent: "flex-start",
      paddingLeft: spacing(1),
    },
  };
});

export { useStyles };
