import React from "react";
export const MostlyCloudy = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="33"
      viewBox="0 0 51 33"
      fill="none"
    >
      <path
        d="M44.8 19.7C49.5 17.8 52 12.6 50.4 7.69998C48.9 2.99998 44.2 0.299979 39.5 1.09998C41.3 2.29998 42.8 4.09998 43.5 6.29998C44.4 8.99998 44 11.8 42.8 14C41.4 12.4 39.3 11.3 37 11.3C35.6 11.3 34.2 11.7 33.1 12.4C32.3 8.09998 28.4 4.89998 23.8 5.19998C19.4 5.39998 15.9 8.59998 15.3 12.8C14.5 12.5 13.7 12.4 12.9 12.4C8.7 12.4 5.3 15.8 5.3 20C5.3 20.1 5.3 20.2 5.3 20.2C3 20.8 0 22 0 26.4C0 29.8 3.1 32.7 6.8 32.7H7.2C9.5 32.6 19.3 32.7 19.3 32.7C19.3 32.7 23.6 32.7 30.5 32.7C30.5 32.7 35.7 32.8 39.5 32.8C40.9 32.8 42.2 32.8 42.8 32.8C46.4 32.6 49.4 29.2 49.2 25.5C49.1 22.8 47.3 20.6 44.8 19.7ZM42.7 30.8C40.2 30.9 30.5 30.8 30.5 30.8C26.2 30.8 19.6 30.8 19.3 30.8C19.3 30.8 14.8 30.7 11.2 30.7C9.5 30.7 7.9 30.7 7.2 30.8H6.9C4.2 30.8 1.9 28.8 1.9 26.4C1.9 23.5 3.5 22.6 6.4 21.8L7.1 21.6V20.4C7.1 20.2 7.1 20.1 7.1 19.9C7.1 16.7 9.7 14.1 12.9 14.1C13.9 14.1 14.8 14.4 15.7 14.9L17.1 15.7V14.1C17 10.3 20 7.19998 23.9 6.99998H24.3C28.1 6.99998 31.3 9.89998 31.4 13.6C31.4 13.7 31.4 13.8 31.4 14.2L31.3 16.6L33 14.9C34.1 13.8 35.6 13.2 37.1 13.2C39 13.2 40.7 14.2 41.8 15.6C40.5 17 39 18 37.3 18.6C36.9 18.7 36.4 18.8 36 18.9C37.9 20.1 40.3 20.7 42.6 20.3L42.5 21.1L43.4 21.3C45.6 21.7 47.3 23.4 47.4 25.5C47.5 27.9 45.5 30.7 42.7 30.8Z"
        fill={color}
      />
    </svg>
  );
};
