const townThemeManagerLabels = {
  TOWN_THEME_MANAGER: "Town Theme Manager",
  SELECT_YOUR_PRIMARY_COLOR: "Select your primary color:",
  SELECT_YOUR_SECONDARY_COLOR: "Select your secondary color:",
  SELECT_YOUR_SIGNUP_BACKGROND_COLOR: "Select your sign up background color:",
  BUILD_YOUR_MASTERHEAD_BUTTONS: "Build your Masterhead Buttons",
  CUSTOMIZE_YOUR_TOP_ACTION_BUTTONS: "Customize your top action buttons",
  CUSTOMIZE_YOUR_BOTTOM_ACTION_BUTTONS: "Customize your bottom action buttons",

  ACTION: "Action: ",
  DEFAULT_ACTION: "Default Action: ",
  BUTTON_COLOR: "Button color: ",
  LABEL_COLOR: "Label color: ",
  BORDER_COLOR: "Border color: ",
  REMOVE_BUTTON_ACTION_CONFIRMATION:
    "Are you sure you want to remove this button?",
  SAVE: "Save",
  SAVING: "Saving...",
  RESTORE_TO_DEFAULTS: "Restore to defaults",
  SURE_TO_RESTORE_TO_DEFAULTS:
    "Are you sure you want to restore the buttons to TAPinto's default?",
  SURE_TO_RESTORE_THEME:
    "Are you sure you want to restore to TAPinto's default theme?",
  CUSTOM_MESSAGES: {
    DIY_SHOPPING_CART_CUSTOM_AGREEMENT: {
      LABEL: "DIY Shopping Cart Terms of Agreement",
      DESCRIPTION:
        "This text will display on the DIY shopping cart page. It should inform the user of the terms and conditions.",
    },
    POST_SUBSCRIPTION_MESSAGE: {
      LABEL: "Post newsletter sign up text",
      DESCRIPTION:
        "This text is included in the email a user receives after signing up for your town's newlsetters.",
    },
    ENTER_YOUR_CONTRIBUTION_THANK_YOU_MESSAGE: {
      LABEL: "Message after DIY content submit",
      DESCRIPTION:
        "This text is show after the user submits a DIY contribution.",
    },
    ENTER_YOUR_SUBSCRIPTION_FOOTER_CONTENT: {
      LABEL: "User confirmation email footer",
      DESCRIPTION:
        "This text is included in the email a user receives after creating a new account.",
    },
    ACCOUNT_NOT_CONFIRMED_MESSAGE: {
      LABEL: "User account not confirmed message",
      DESCRIPTION:
        "This text lets the user know their account has not yet been confirmed.",
    },
    USER_ACCOUNT_CONFIRMATION_MESSAGE: {
      LABEL: "User account confirmation instructions",
      DESCRIPTION:
        "This text should describe the steps a user needs to follow to confirm a their account after confirmation email has been sent out.",
    },
    SIGN_UP_FORM_FOOTER_TEXT: {
      LABEL: "Sign up form footer text",
      DESCRIPTION:
        "This text will be displayed at the footer of all sign up forms on your site. It should inform the user of the usage or re-captcha and any other terms and conditions.",
    },
    POST_PASSWORD_CREATION_MESSAGE: {
      LABEL: "Post password creation message",
      DESCRIPTION:
        "This text will be displayed after a user creates a password for the first time.",
    },
    POST_PASSWORD_RESET_INSTRUCTIONS: {
      LABEL: "Post password reset instructions",
      DESCRIPTION:
        "This text should describe the steps needed to confirm a user account after confirmation email has been sent out.",
    },
    NEWSLETTER_FOOTER_ADDRESS: {
      LABEL: "Newsletter footer address",
      DESCRIPTION:
        "This text will appear at the bottom of the confirmation letter sent to the user after they subscribe to the newsletter.",
    },
    LETTER_TO_THE_EDITOR_DISCLAIMER: {
      LABEL: "Letter to the editor disclaimer",
      DESCRIPTION:
        "This text will display at the bottom of any letter to the editor published. It will also display at the bottom of the DIY form.",
    },
    COLUMN_DISCLAIMER: {
      LABEL: "Column disclaimer",
      DESCRIPTION:
        "This text will display at the bottom of any column published.",
    },
    CANDIDATE_STATEMENT_DISCLAIMER: {
      LABEL: "Candidate statement disclaimer",
      DESCRIPTION:
        "This text will display at the bottom of any candidate staatement published.",
    },
    PRESS_RELEASE_DISCLAIMER: {
      LABEL: "Press release disclaimer",
      DESCRIPTION:
        "This text will display at the bottom of any press release published through a DIY form.",
    },
  },
  SELECT_MASTHEAD_BACKGROUND: "Select your masthead background color",
  SELECT_MASTHEAD_CONTRAST: "Select your masthead contrast color",
  SELECT_A_TEMPLATE_FOR_TOP_LOCAL_NEWS: "Select a template for Top Local News",
  SELECT_A_TEMPLATE_FOR_MASTERHEAD: "Select a template for Masterhead",
};

export default townThemeManagerLabels;
