import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../Button/ButtonIcon";
import HamburgerButton from "../Icons/HamburgerButton";
import Typography from "../Typography/Typography";
import { useStyles } from "./MenuButton.style";
import labels from "../../config/labels";
import { useTownTheme } from "../../context/TownThemeContext";

const MenuButton = ({ toggleMenu, withText, useThemeColors, color = 'white' }) => {
  const classes = useStyles();
  const { themeColors } = useTownTheme();

  return (
    <ButtonIcon
      className={classes.button_icon}
      children={
        <>
          <HamburgerButton
            className={classes.hamburger_menu_icon}
            fillColor={
              useThemeColors ? themeColors?.masthead_colors?.contrast : color
            }
          />
          {withText && (
            <Typography
              level="t1_text_1"
              style={{
                color: useThemeColors
                  ? themeColors?.masthead_colors?.contrast
                  : color,
              }}
            >
              {labels.MENU}
            </Typography>
          )}
        </>
      }
      onClick={toggleMenu}
      ariaLabel="Toggle Menu"
    />
  );
};

MenuButton.propTypes = {
  toggleMenu: PropTypes.func,
  withText: PropTypes.bool,
  useThemeColors: PropTypes.bool,
};

MenuButton.defaultProps = {
  withText: true,
  useThemeColors: true,
};

export default MenuButton;
