import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareLinkedinBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="2" fill="white" />
      <path
        d="M9.2173 13.641H12.1119V22.9808H9.2173V13.641ZM10.6935 9C11.0252 9.00001 11.3494 9.09822 11.6252 9.28228C11.9011 9.46633 12.1163 9.72796 12.2436 10.0342C12.371 10.3404 12.4048 10.6774 12.3408 11.0028C12.2768 11.3282 12.1178 11.6274 11.884 11.8626C11.6502 12.0978 11.352 12.2584 11.0269 12.3243C10.7019 12.3901 10.3647 12.3582 10.0577 12.2326C9.75078 12.1071 9.48792 11.8934 9.30229 11.6186C9.11666 11.3438 9.01658 11.0201 9.01468 10.6885C9.01341 10.4672 9.05589 10.2479 9.13969 10.0431C9.2235 9.83826 9.34696 9.65205 9.50298 9.49513C9.659 9.33821 9.8445 9.21369 10.0488 9.12871C10.2532 9.04374 10.4723 9 10.6935 9ZM13.9644 13.641H16.7528V14.9146C17.0319 14.4382 17.4351 14.0466 17.9195 13.7815C18.4037 13.5164 18.951 13.3878 19.5026 13.4094C22.3972 13.4094 22.9858 15.3391 22.9858 17.8574V22.9808H20.0912V18.4652C20.0912 17.3845 20.0912 15.9855 18.586 15.9855C17.0809 15.9855 16.8493 17.1627 16.8493 18.3784V23H13.9547L13.9644 13.641Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareLinkedinBlack;
