import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareInstagramBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="2" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5367 16.0156C20.5367 13.5212 18.51 11.4944 16.0156 11.4944C13.5212 11.4944 11.4944 13.5212 11.4944 16.0156C11.4944 18.51 13.5212 20.5367 16.0156 20.5367C18.51 20.5367 20.5367 18.51 20.5367 16.0156ZM20.4432 10.8708C20.0379 10.8708 19.7261 11.2138 19.7261 11.588C19.7261 11.9933 20.0379 12.3051 20.4432 12.3051C20.8486 12.3051 21.1604 11.9933 21.1604 11.588C21.1915 11.2138 20.8486 10.8708 20.4432 10.8708ZM12.3051 9.81069H19.6949C21.0668 9.81069 22.1893 10.9332 22.1893 12.3051V19.6949C22.1893 21.0668 21.0668 22.1893 19.6949 22.1893H12.3051C10.9332 22.1893 9.81069 21.0668 9.81069 19.6949V12.3051C9.81069 10.9332 10.9332 9.81069 12.3051 9.81069ZM19.7261 9H12.3051C10.4655 9 9 10.4967 9 12.3051V19.6949C9 21.5345 10.4967 23 12.3051 23H19.6949C21.5345 23 23 21.5033 23 19.6949V12.3051C23.0312 10.4967 21.5345 9 19.7261 9ZM12.4298 16.0156C12.4298 14.02 14.0512 12.4298 16.0156 12.4298C17.98 12.4298 19.6013 14.0512 19.6013 16.0156C19.6013 18.0111 17.98 19.6013 16.0156 19.6013C14.0512 19.6013 12.4298 17.98 12.4298 16.0156Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareInstagramBlack;
