import React, { useState } from "react";
import Button from "../../common/Button/Button";
import clsx from "clsx";
import { useStyles } from "./MobileSearch.style";
import { useTownTheme } from "../../context/TownThemeContext";
import Box from "../../common/Box/Box";
import SearchField from "../../common/SearchField/SearchField";
import labels from "../../config/labels";
import { handleSearch } from "../../utils/helper";
const GRAY = "#9C9C9C";

const MobileSearch = ({ openState, color }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const { themeColors, town } = useTownTheme();
  return (
    <Box
      classes={{
        root: clsx(openState && classes.mobile_search, classes.hidden),
      }}
      style={{
        backgroundColor: color ? color : themeColors?.masthead_colors?.background,
        position: "relative",
      }}
    >
      {openState && (
        <>
          <SearchField
            placeholder={labels.SEARCH}
            onChange={setSearchText}
            startIcon
            startIconFill={GRAY}
            classes={{
              text_field: classes.search_text_field,
              start_icon: classes.textfield_search_icon,
            }}
            onKeyPress={(key) => {
              if (key === "Enter" || key === "13") {
                handleSearch(town, searchText);
              }
            }}
          />
          <Button
            level="simple_button"
            variant="primary"
            classes={{ root: clsx(classes.button, classes.button_label) }}
            onClick={() => handleSearch(town, searchText)}
          >
            {labels.GO}
          </Button>
        </>
      )}
    </Box>
  );
};

MobileSearch.propTypes = {};

export default MobileSearch;
