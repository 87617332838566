import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../../../common/Theme/Theme";
import values from "../../../../../../common/Theme/themes/breakpoints/values";
import { AspectRatio } from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%"
    },
    four_cards_container: {
      display: 'flex',
      gap: theme.spacing(5),
      [theme.breakpoints.down(768)]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: 0,
      }
    },
    half_content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down(values.xs)]: {
        gap: 0,
      }
    },
    information_container: {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      ["& a h5"]: {
        fontWeight: "500 !important",
      },
      ["& a"]: {
        padding: 0,
      },
    },
    image: {
      maxHeight:  theme.spacing(31.25),
    },
    horizontal_image: {
      maxHeight:  theme.spacing(12.5),
    },
    horizontal_image_license: {
      maxHeight:  theme.spacing(31.25),
    },
    media_container_link: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme.spacing(15.25),
      height: theme.spacing(12.5),
      [theme.breakpoints.down(values.sm)]: {
        width: '100%',
        height: theme.spacing(28.75),
      },
    },
    media_container_link_license: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: '16/9',
      [theme.breakpoints.down(values.sm)]: {
        width: '100%',
        height: theme.spacing(28.75),
      },
    },
    media_container: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
    },

    media_information_container: {
      gap: theme.spacing(2),
    },
  }
});

export default useStyles;