import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    card_container: {
      width: "32%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
        marginRight: "0px !important",
        marginBottom: theme.spacing(2),
      },
    },
    content_header: {
      paddingTop: theme.spacing(1.5),
      "& h3": {
        lineHeight: "28px",
        fontWeight: 600,
      },
    },
    content_description: {
      fontWeight: 400,
    },
    content_card: {
      marginBottom: 0,
      height: "100%",
    },
    two_cards_layout: {
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "space-between",
    },
    image: {
      maxHeight: theme.spacing(21.25),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    image_license: {
      maxHeight: theme.spacing(50),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container: {
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
      fontSize: `${theme.spacing(1.5)} !important`,
      height: theme.spacing(21.25),
      maxHeight: theme.spacing(21.25),
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container_license: {
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
      fontSize: `${theme.spacing(1.5)} !important`,
      aspectRatio: "16/9",
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    information_container: {
      padding: `${theme.spacing(2)}px 0px`,
    },
    information: {
      width: "100%",
      display: "block",
    },
    padding_16: {
      padding: theme.spacing(2),
    },
    actions_main_container: {
      padding: `${theme.spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
  };
});

export { useStyles };
