import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "auto",
      backgroundColor: theme.palette.grayVariation.grayLightFooter,
      display: "flex",
      gap: theme.spacing(4),
      paddingLeft: theme.spacing(17),
      paddingRight: theme.spacing(21),
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
      justifyContent: "space-between",
      [theme.breakpoints.down(900)]: {
        height: "auto",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.miscellaneous.lightBlack,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    block_container: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      [theme.breakpoints.down(900)]: {
        textAlign: "center",
        justifyContent: "center",
      },
    },
    subscriber_block: {
      maxWidth: theme.spacing(42),
      display: "flex",
      flexDirection: "column",
    },
    block_link: {
      display: "block",
      lineHeight: "1",
    },
    first_block_link: {
      textTransform: "uppercase",
    },
    follow_us: {
      [theme.breakpoints.up(1150)]: {
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down(1150)]: {
        paddingLeft: theme.spacing(2),
      },
      textAlign: "center",
      textTransform: "uppercase",
      display: "block",
    },
    social_media_container: {
      display: "flex",
      justifyContent: "space-around",
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down(900)]: {
        justifyContent: "space-between",
        alignSelf: "center",
        paddingBottom: theme.spacing(4),
      },
    },
    social_media_icon: {
      paddingRight: theme.spacing(2),
    },
    divider_container: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
    divider: {
      border: "1px solid #333333",
    },
    rss_icon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: "block",
    },
  };
});

export { useStyles };
