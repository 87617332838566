import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareTiktok = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <path
        d="M28.937 0H3.06302C1.37136 0 0 1.37136 0 3.06302V28.937C0 30.6286 1.37136 32 3.06302 32H28.937C30.6286 32 32 30.6286 32 28.937V3.06302C32 1.37136 30.6286 0 28.937 0Z"
        fill="black"
      />
      <path
        d="M24.1125 12.7083V15.7289C23.5838 15.6772 22.8989 15.558 22.1396 15.2794C21.1479 14.9161 20.4101 14.4193 19.9274 14.0344V20.1408L19.9158 20.1217C19.9244 20.2422 19.9274 20.3658 19.9274 20.49C19.9274 23.5229 17.4602 25.9914 14.4261 25.9914C11.3919 25.9914 8.92468 23.5229 8.92468 20.49C8.92468 17.4571 11.3919 14.9874 14.4261 14.9874C14.7224 14.9874 15.0144 15.0114 15.2991 15.0569V18.0344C15.0255 17.9361 14.7322 17.8838 14.4261 17.8838C12.9899 17.8838 11.8205 19.052 11.8205 20.4894C11.8205 21.9268 12.9899 23.095 14.4261 23.095C15.8623 23.095 17.0317 21.9256 17.0317 20.4894C17.0317 20.4353 17.0304 20.3818 17.0267 20.3277V8.46112H20.0461C20.0572 8.71688 20.0676 8.97388 20.0787 9.23026C20.099 9.73379 20.2785 10.217 20.5902 10.613C20.9573 11.0784 21.4977 11.6188 22.2582 12.0504C22.9696 12.4537 23.6385 12.6271 24.1119 12.7083H24.1125Z"
        fill="#FF004F"
      />
      <path
        d="M23.0753 10.2558V13.2764C22.5466 13.2247 21.8617 13.1054 21.1024 12.8269C20.1107 12.4636 19.3729 11.9668 18.8902 11.5819V17.6883L18.8786 17.6692C18.8872 17.7897 18.8902 17.9133 18.8902 18.0375C18.8902 21.0704 16.423 23.5389 13.3889 23.5389C10.3547 23.5389 7.88748 21.0704 7.88748 18.0375C7.88748 15.0046 10.3547 12.5349 13.3889 12.5349C13.6852 12.5349 13.9772 12.5589 14.2619 12.6044V15.5819C13.9883 15.4835 13.695 15.4313 13.3889 15.4313C11.9527 15.4313 10.7833 16.5994 10.7833 18.0369C10.7833 19.4743 11.9527 20.6425 13.3889 20.6425C14.8251 20.6425 15.9945 19.4731 15.9945 18.0369C15.9945 17.9828 15.9932 17.9293 15.9895 17.8752V6.00922H19.0089C19.02 6.26498 19.0304 6.52197 19.0415 6.77835C19.0618 7.28189 19.2413 7.76513 19.5536 8.16108C19.9207 8.62649 20.4611 9.16692 21.2216 9.59852C21.933 10.0018 22.6019 10.1752 23.0753 10.2564V10.2558Z"
        fill="#00F7EF"
      />
      <path
        d="M23.7356 11.5764V14.597C23.2069 14.5453 22.522 14.4261 21.7627 14.1476C20.771 13.7842 20.0332 13.2874 19.5506 12.9026V19.0089L19.5389 18.9899C19.5475 19.1104 19.5506 19.2339 19.5506 19.3581C19.5506 22.391 17.0833 24.8595 14.0492 24.8595C11.0151 24.8595 8.54779 22.391 8.54779 19.3581C8.54779 16.3252 11.0151 13.8555 14.0492 13.8555C14.3455 13.8555 14.6376 13.8795 14.9222 13.925V16.9026C14.6486 16.8042 14.3554 16.7519 14.0492 16.7519C12.613 16.7519 11.4436 17.9201 11.4436 19.3575C11.4436 20.795 12.613 21.9631 14.0492 21.9631C15.4854 21.9631 16.6548 20.7937 16.6548 19.3575C16.6548 19.3034 16.6535 19.2499 16.6499 19.1958V7.32985H19.6692C19.6803 7.58561 19.6907 7.84261 19.7018 8.09899C19.7221 8.60252 19.9016 9.08577 20.2133 9.48171C20.5804 9.94713 21.1208 10.4876 21.8813 10.9192C22.5927 11.3225 23.2616 11.4959 23.735 11.577L23.7356 11.5764Z"
        fill="white"
      />
    </svg>
  );
};

export default SquareTiktok;
