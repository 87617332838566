import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareRssFeedBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <path
        d="M16.1611 11.3626C13.9927 9.19421 11.1076 8 8.03711 8V10.2592C10.5041 10.2592 12.822 11.2184 14.5637 12.9601C16.3054 14.7018 17.2646 17.0197 17.2646 19.4867H19.5238C19.5237 16.4162 18.3295 13.531 16.1611 11.3626Z"
        fill="black"
      />
      <path
        d="M8.01367 11.823V14.0822C10.9937 14.0822 13.4182 16.5066 13.4182 19.4867H15.6774C15.6774 15.2609 12.2395 11.823 8.01367 11.823Z"
        fill="black"
      />
      <path
        d="M9.61808 19.5495C10.5117 19.5495 11.2362 18.8251 11.2362 17.9314C11.2362 17.0378 10.5117 16.3134 9.61808 16.3134C8.72444 16.3134 8 17.0378 8 17.9314C8 18.8251 8.72444 19.5495 9.61808 19.5495Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareRssFeedBlack;
