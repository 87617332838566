import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareBluesky = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="4" fill="#EEEEEE" />
      <path
        d="M10.8697 9.07694C12.9462 10.6412 15.1801 13.8124 16 15.5143V20.0091C16 19.9135 15.9631 20.0217 15.884 20.2545C15.456 21.5157 13.7844 26.4376 9.96231 22.5028C7.94976 20.4312 8.88146 18.3594 12.5449 17.7339C10.4491 18.0916 8.09296 17.5005 7.44655 15.1825C7.26031 14.5157 6.94333 10.4086 6.94333 9.85383C6.94333 7.07514 9.37136 7.94854 10.8697 9.07694ZM21.1303 9.07694C19.0538 10.6412 16.8199 13.8124 16 15.5143V20.0091C16 19.9135 16.0367 20.0217 16.116 20.2545C16.5439 21.5157 18.2154 26.4376 22.0376 22.5028C24.0502 20.4312 23.1185 18.3594 19.4551 17.7339C21.5508 18.0916 23.907 17.5005 24.5534 15.1825C24.7397 14.5157 25.0567 10.4086 25.0567 9.85383C25.0567 7.07514 22.6289 7.94854 21.1303 9.07694Z"
        fill="url(#paint0_linear_16026_10020)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16026_10020"
          x1="6.94333"
          y1="8.00001"
          x2="6.94333"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0A7AFF" />
          <stop offset="1" stop-color="#59B9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SquareBluesky;
