import React, { Fragment, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useStyles } from "./HamburgerMenu.style";
import MenuDrawer from "../../common/Menu/components/MenuDrawer/MenuDrawer";
import MenuList from "../../common/Menu/components/MenuList/MenuList";
import MenuListItem from "../../common/Menu/components/MenuListItem/MenuListItem";
import TriangleArrowForward from "../../common/Icons/TriangleArrowForward";
import HorizontalDivider from "../../common/HorizontalDivider/HorizontalDivider";
import Typography from "../../common/Typography/Typography";
import SubmenuForMobile from "./SubmenuForMobile/SubmenuForMobile";
import { checkIfIsNotTheLastItem, redirectToPage } from "../../utils/helper";
import Button from "../../common/Button/Button";
import theme from "../../common/Theme/themes/default";
import { useTownTheme } from "../../context/TownThemeContext";
import {
  itemHasSubmenu,
  isSelectedMenuItem,
} from "../../services/hamburgerMenu";
import AdminButtons from "./AdminButtons/AdminButtons";
import { bool, func, object, string } from "prop-types";
import { useMastheadContext } from "../../context/MastheadContext";
import { Weather } from "../Weather/Weather";
import SubmitContentModal from "../Modals/SubmitContentModal/SubmitContentModal";

const WHITE = "white";
const TOP_TARGET = "_top";
const BLANK_TARGET = "_blank";
const DEFAULT_ITEMS_WITH_SUBMENU = ["News", "Admin", "Opinion"];

const HamburgerMenu = ({
  openState,
  legalNoticePermissions,
  toggleMenu,
  townSlug,
  section,
  currentUser,
  isPathUserSignIn,
  overallPage,
}) => {
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const [submitContentButton, setSubmitContentButton] = useState();
  const [newsletterSignupButton, setNewsletterSignupButton] = useState();
  const classes = useStyles();
  const referenceForSubmenu = useRef();
  const [subMenuOptions, setSubMenuOptions] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState();
  const { firstBlockButtons, signUpPath } = useTownTheme();
  const { hamburgerMenuItems, adminMenuItems, townsWithClusters, displayWeather } =
    useMastheadContext();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;

  useEffect(() => {
    const submitButton = firstBlockButtons.find((button) =>
      button.action.includes("submit")
    );
    if (submitButton) {
      setSubmitContentButton(submitButton);
    }

    const newsletterButton = firstBlockButtons.find(
      (button) =>
        button.action.includes("signup") ||
        button.action.includes("subscription")
    );
    if (newsletterButton) {
      setNewsletterSignupButton(newsletterButton);
    }

    return () => setNewsletterSignupButton();
  }, [firstBlockButtons]);

  const handleMenuItemClick = (item) => {
    if (selectedItemId === item.id) {
      setSelectedItemId();
      setSubMenuOptions([]);
    } else {
      setSelectedItemId(item.id);
    }
    if (item?.submenu_items?.length > 0) {
      setSubMenuOptions(item.submenu_items);
    } else if (item?.submenu_items?.length === 0) {
      window.open(
        item.path,
        item.id === "advertise" || item.id === "franchise"
          ? BLANK_TARGET
          : TOP_TARGET
      );
    }
  };

  const isDefaultItemWithEmptySubmenu = (item) => {
    return (
      DEFAULT_ITEMS_WITH_SUBMENU.includes(item.name) && !itemHasSubmenu(item)
    );
  };

  const handleButtonClick = (button) => {
    if (button.action === "default_newsletter_signup") {
      redirectToPage(signUpPath, TOP_TARGET);
      return;
    }

    redirectToPage(button.action, TOP_TARGET);
    return;
  };

  return (
    <>
      <MenuDrawer
        anchor="left"
        openState={openState}
        onClose={toggleMenu}
        variant="temporary"
        classes={{
          paper: classes.drawer_paper,
          root: classes.drawer_root,
        }}
      >
        {displayWeather && (
          <div className={classes.weather_container}>
            <Weather
              townSlug={townSlug}
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
              isLicenses={true}
            />
          </div>
        )}
        <MenuList disablePadding={true}>
          {hamburgerMenuItems.map((item, index) => {
            if (!isDefaultItemWithEmptySubmenu(item)) {
              return (
                <Fragment key={`menu-fragment-${index}`}>
                  <MenuListItem
                    title={item.name}
                    innerRef={referenceForSubmenu}
                    classes={{
                      root: classes.menu_item,
                      gutters: classes.menu_item_gutters,
                    }}
                    disableGutters={false}
                    key={`menu-list-item-${index}`}
                    onClick={() => handleMenuItemClick(item)}
                    divider={false}
                  >
                    <Typography level="paragraph_1" color={WHITE}>
                      {item.name}
                    </Typography>
                    {itemHasSubmenu(item) && (
                      <TriangleArrowForward
                        className={clsx(
                          isSelectedMenuItem(selectedItemId, item) &&
                            classes.rotated_arrow,
                          classes.arrow
                        )}
                        fillColor={primary}
                      />
                    )}
                  </MenuListItem>
                  {checkIfIsNotTheLastItem(index, hamburgerMenuItems) && (
                    <HorizontalDivider className={classes.divider} />
                  )}
                  {isSelectedMenuItem(selectedItemId, item) && (
                    <SubmenuForMobile subMenuItems={subMenuOptions} />
                  )}
                </Fragment>
              );
            }
          })}
        </MenuList>

        {newsletterSignupButton && (
          <MenuList disablePadding classes={{ root: classes.buttons_list }}>
            <Fragment key={newsletterSignupButton.action}>
              <Button
                level="simple_button"
                color="primary"
                onClick={() => handleButtonClick(newsletterSignupButton)}
                children={newsletterSignupButton.label}
                classes={{
                  root: classes.button,
                  label: classes.button_label,
                }}
                style={{
                  backgroundColor: newsletterSignupButton.background_color,
                  color: newsletterSignupButton.label_color,
                }}
                ariaLabel={newsletterSignupButton.label}
                dataCy={newsletterSignupButton.label}
              />
            </Fragment>
          </MenuList>
        )}

        {!overallPage && (
          <AdminButtons
            submitContentButton={submitContentButton}
            setOpenSubmitContentPopUp={setOpenSubmitContentPopUp}
            currentUser={currentUser}
            adminMenuItems={adminMenuItems}
            townsWithClusters={townsWithClusters}
            isPathUserSignIn={isPathUserSignIn}
          />
        )}
      </MenuDrawer>
      <SubmitContentModal
        town={{ id: townSlug }}
        currentUserId={currentUser?.email}
        legalNoticePermissions={legalNoticePermissions}
        handleClose={() => setOpenSubmitContentPopUp(false)}
        openState={openSubmitContentPopUp}
      />
    </>
  );
};

HamburgerMenu.propTypes = {
  openState: bool,
  legalNoticePermissions: object,
  toggleMenu: func,
  townSlug: string,
  sectionSlug: string,
  currentUser: object,
  isPathUserSignIn: bool,
  overallPage: bool,
};

HamburgerMenu.defaultProps = {
  overallPage: false,
};

export default HamburgerMenu;
