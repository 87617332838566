import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  getDisplayBlocks,
  getSponsors,
  getVideoAd,
  hasLockedBullseye,
} from "../services/homePage";
import {
  fetchTheme,
  loadNearbyTowns,
  loadMasterheadDesign,
} from "../services/townService";
import { fetchMastheadLogoImage } from "../services/masthead";

const TownContext = React.createContext({});

const TownContextProvider = ({ children }) => {
  const [town, setTown] = useState();
  const [leftBlocks, setLeftBlocks] = useState([]);
  const [rightBlocks, setRightBlocks] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [videoAd, setVideoAd] = useState(null);
  const [townSlug, setTownSlug] = useState("");
  const [isHalstonMedia, setIsHalstonMedia] = useState(false);
  const [adTargets, setAdTargets] = useState();
  const [nearbyTowns, setNearbyTowns] = useState([]);
  const [themeTemplate, setThemeTemplate] = useState();
  const [topStoriesTemplate, setTopStoriesTemplate] = useState();
  const [mastheadImage, setMastheadImage] = useState("");
  const [templateMasterhead, setTemplateMasterhead] = useState("default");
  const [hasLockedBullseyeAd, setHasLockedBullseyeAd] = useState(null);

  const loadDisplayBlocks = async (town, side = null) => {
    const response = await getDisplayBlocks(town.id, side);
    if (response && response?.status === 200) {
      const display_blocks = response?.data?.display_blocks;
      setLeftBlocks(display_blocks?.left_blocks);
      setRightBlocks(display_blocks?.right_blocks);
    }
  };

  useEffect(() => {
    const fetchNearbyTowns = async () => {
      const response = await loadNearbyTowns(townSlug);
      if (response?.status === 200) {
        setNearbyTowns(response?.data?.nearby_towns);
      }
    };

    if (townSlug === "halston-media") setIsHalstonMedia(true);
    if (townSlug) fetchNearbyTowns();

    return () => {
      setIsHalstonMedia(false), setNearbyTowns([]);
    };
  }, [townSlug]);

  const townMutation = useCallback((town) => {
    setTown(town);

    const loadVideoAd = async () => {
      const response = await getVideoAd(town.id);
      if (response && response?.status === 200) {
        setVideoAd(response?.data?.video_ad_html);
        setHasLockedBullseyeAd(response?.data?.has_locked_bullseye);
      }
    };

    const buildAdTargets = () => {
      setAdTargets({ towns: [town?.slug_for_gam] });
    };

    const loadMastheadImage = async (payload = {}) => {
      const response = await fetchMastheadLogoImage(payload);
      if (response && response?.status === 200) {
        setMastheadImage(response?.data?.logo_image);
      }
    };

    const loadTheme = async () => {
      const response = await fetchTheme(town?.slug);

      if (response?.status === 200) {
        setThemeTemplate(response?.data?.theme_template);
        setTopStoriesTemplate(
          response?.data?.theme_template?.top_stories_template
        );
      }
    };
    const loadSponsors = async () => {
      const response = await getSponsors(town.id);
      if (response && response?.status === 200) {
        setSponsors(response?.data?.sponsors);
      }
    };

    if (town) {
      loadVideoAd();
      buildAdTargets();
      loadTheme();
      loadMastheadImage({ town_id: town.slug });
      loadDisplayBlocks(town);
      loadSponsors();
    }
  }, []);

  const loadMasterheadTemplate = async () => {
    const response = await loadMasterheadDesign(town.id);
    if (response?.status === 200) {
      const masterheadTemplateId =
        response?.data?.town_template?.masterhead_template_id;
      setTemplateMasterhead(masterheadTemplateId);
    }
  };

  const loadBlocksBySideAndTown = useCallback((town, side) => {
    if (town && side) {
      loadDisplayBlocks(town, side);
    }
  }, []);
  return (
    <TownContext.Provider
      value={{
        setTown,
        leftBlocks,
        rightBlocks,
        sponsors,
        videoAd,
        town,
        townSlug,
        setTownSlug,
        isHalstonMedia,
        adTargets,
        nearbyTowns,
        themeTemplate,
        topStoriesTemplate,
        townMutation,
        mastheadImage,
        templateMasterhead,
        loadBlocksBySideAndTown,
        hasLockedBullseyeAd,
        loadMasterheadTemplate,
      }}
    >
      {children}
    </TownContext.Provider>
  );
};

TownContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useTown = () => {
  const TownContextValues = useContext(TownContext);

  if (!TownContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the TownContextProvider"
    );
  }

  return TownContextValues;
};

const withTownContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <TownContextProvider>
      <Component {...props} />
    </TownContextProvider>
  );

  return ComponentWithProvider;
};

export { useTown, withTownContextProvider };
