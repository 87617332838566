import React from "react";
export const Sleet = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
    >
      <path
        d="M35.3945 11.5084C35.3945 11.3464 35.3945 11.1844 35.3945 10.9414C35.3945 7.62067 32.7217 4.94786 29.401 4.94786C28.267 4.94786 27.2141 5.27183 26.2422 5.83879C25.6752 2.43703 22.5164 -0.154792 18.7907 0.00719682C15.3079 0.169186 12.4731 2.76101 12.0682 6.00079C11.4202 5.7578 10.7723 5.67681 10.1243 5.67681C6.80353 5.67681 4.1307 8.34962 4.1307 11.6704C4.1307 11.7514 4.1307 11.8324 4.1307 11.9134C2.42981 12.3993 0 13.3713 0 16.773C0 19.3649 2.10584 21.5517 5.02164 21.6327L5.91258 20.2558C5.83159 20.2558 5.66961 20.2558 5.58861 20.2558H5.34561C3.15876 20.2558 1.37689 18.6359 1.37689 16.692C1.37689 14.3432 2.6728 13.6143 5.02164 13.0473L5.58861 12.8853V11.9944C5.58861 11.8324 5.58861 11.7514 5.58861 11.5894C5.58861 8.99758 7.69445 6.97272 10.2053 6.97272C11.0152 6.97272 11.7442 7.2157 12.4731 7.62067L13.5261 8.18763V6.97272C13.4451 3.97592 15.8749 1.4651 19.0337 1.30311H19.3577C22.4355 1.30311 24.9463 3.65194 25.1083 6.56774C25.1083 6.64874 25.1083 6.81073 25.1083 7.05371L25.0273 8.83559L26.3232 7.53968C27.2141 6.64874 28.348 6.16277 29.6439 6.16277C32.2358 6.16277 34.2606 8.26863 34.2606 10.7795C34.2606 11.1034 34.2606 11.4274 34.1796 11.6704L34.0177 12.3993L34.7466 12.5613C36.5285 12.8853 37.9054 14.2622 37.9864 15.9631C38.0674 17.664 36.9335 19.6079 35.1516 20.1748L35.6375 21.4707C37.9054 20.7418 39.5253 18.3929 39.4443 15.8821C38.9583 13.9382 37.5004 12.1564 35.3945 11.5084Z"
        fill={color}
      />
      <path
        d="M11.5012 24.0625C9.15237 20.9038 9.31437 15.5581 9.23337 17.259C8.90939 21.7137 7.20851 24.0625 7.20851 24.0625C7.04652 24.3055 6.96551 24.6295 6.96551 24.9535C6.96551 26.1684 8.01844 27.1403 9.39535 27.1403C10.6913 27.1403 11.8252 26.1684 11.8252 24.9535C11.7442 24.6295 11.6632 24.3055 11.5012 24.0625Z"
        fill={color}
      />
      <path
        d="M18.8718 19.7698C16.8469 17.016 17.0089 12.3184 16.8469 13.8572C16.6039 17.745 15.065 19.7698 15.065 19.7698C14.903 20.0128 14.822 20.2558 14.822 20.5798C14.822 21.6327 15.794 22.4427 16.9279 22.4427C18.0618 22.4427 19.0338 21.6327 19.0338 20.5798C19.1147 20.3368 19.0337 20.0128 18.8718 19.7698Z"
        fill={color}
      />
      <path
        d="M16.7659 29.8941C15.227 27.7883 15.308 24.1435 15.227 25.2775C15.065 28.3552 13.8501 29.8941 13.8501 29.8941C13.7691 30.0561 13.6881 30.2991 13.6881 30.5421C13.6881 31.352 14.417 32 15.308 32C16.1989 32 16.9279 31.352 16.9279 30.5421C16.9279 30.2991 16.9279 30.0561 16.7659 29.8941Z"
        fill={color}
      />
      <path
        d="M12.7971 14.5862C11.6632 13.0473 11.7442 10.2935 11.6632 11.1844C11.5012 13.4523 10.6103 14.5862 10.6103 14.5862C10.5293 14.7482 10.5293 14.9102 10.5293 15.0722C10.5293 15.6391 11.0962 16.1251 11.7442 16.1251C12.3922 16.1251 12.9591 15.6391 12.9591 15.0722C12.9591 14.9102 12.8781 14.7482 12.7971 14.5862Z"
        fill={color}
      />
      <path
        d="M25.2702 15.3151C25.2702 16.2061 24.5413 16.935 23.6503 16.935C22.7594 16.935 22.0304 16.2061 22.0304 15.3151C22.0304 14.4242 22.7594 13.6953 23.6503 13.6953C24.6222 13.7763 25.2702 14.4242 25.2702 15.3151Z"
        fill={color}
      />
      <path
        d="M32.1547 20.1748C32.1547 21.0658 31.4258 21.7947 30.5348 21.7947C29.6439 21.7947 28.915 21.0658 28.915 20.1748C28.915 19.2839 29.6439 18.5549 30.5348 18.5549C31.4258 18.5549 32.1547 19.2839 32.1547 20.1748Z"
        fill={color}
      />
      <path
        d="M26.2421 30.3801C26.2421 31.271 25.5131 32 24.6222 32C23.7313 32 23.0023 31.271 23.0023 30.3801C23.0023 29.4892 23.7313 28.7602 24.6222 28.7602C25.5131 28.8412 26.2421 29.4892 26.2421 30.3801Z"
        fill={color}
      />
      <path
        d="M31.2638 14.7482C31.2638 15.3961 30.7778 15.8821 30.1298 15.8821C29.4819 15.8821 28.9959 15.3961 28.9959 14.7482C28.9959 14.1002 29.4819 13.6143 30.1298 13.6143C30.7778 13.6143 31.2638 14.1002 31.2638 14.7482Z"
        fill={color}
      />
      <path
        d="M26.4041 22.3617C26.4041 23.1716 25.7562 23.8196 24.9463 23.8196C24.1363 23.8196 23.4884 23.1716 23.4884 22.3617C23.4884 21.5517 24.1363 20.9038 24.9463 20.9038C25.7562 20.9038 26.4041 21.5517 26.4041 22.3617Z"
        fill={color}
      />
      <path
        d="M31.9927 26.9783C31.9927 27.7883 31.3447 28.4362 30.5348 28.4362C29.7248 28.4362 29.0769 27.7883 29.0769 26.9783C29.0769 26.1684 29.7248 25.5204 30.5348 25.5204C31.3447 25.5204 31.9927 26.1684 31.9927 26.9783Z"
        fill={color}
      />
    </svg>
  );
};
