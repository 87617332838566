import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  masterhead_container: {
    zIndex: 999,
    width: "100%",
    padding: `${theme.spacing(2)}px 0px`,
  },
  icons: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(0.5),
  },
  mobile_town_picker_title: {
    "&.MuiDialogTitle-root": {
      textTransform: "capitalize",
      margin: 0,
      background: theme.palette.themeColor.primary,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(6),
    },
  },
  search_icon: {
    marginRight: `${theme.spacing(2)}px !important`,
  },
  button_icon: {
    padding: "0px !important",
  },
  grid_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iframe_image_logo: {
    maxWidth: theme.spacing(10),
  },
}));

export { useStyles };
