import React, { useState, useEffect } from "react";
import { bool, object, string } from "prop-types";
import clsx from "clsx";
import { format } from "date-fns";
import { useStyles } from "./TopNavBar.style";
import labels from "../../config/labels";
import withConfig from "../../utils/withConfig";
import ButtonIcon from "../../common/Button/ButtonIcon";
import HamburgerMenu from "../../components/HamburgerMenu/HamburgerMenu";
import {
  sendMessageToParent,
  handleSearch,
  redirectToHomepage,
} from "../../utils/helper";
import Home from "../../common/Icons/Home";
import { urlToUseOnIframe } from "../../utils/halstonMediaHelper";
import CustomButtons from "./CustomButtons/CustomButtons";
import MenuButton from "../../common/MenuButton/MenuButton";
import { Weather } from "../Weather/Weather";
import { useTownTheme } from "../../context/TownThemeContext";
import Sponsor from "../Sponsor/Sponsor";
import Grid from "../../common/Grid/Grid";
import TownPickerWithNearbyTowns from "./TownPickerWithNearbyTowns/TownPickerWithNearbyTowns";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import values from "../../common/Theme/themes/breakpoints/values";
import SearchField from "../../common/SearchField/SearchField";
import SquareRssFeed from "../../common/Icons/Social/SquareRssFeed";
import Link from "../../common/Link/Link";
import theme from "../../common/Theme/themes/default";
import { useMemo } from "react";
import { useMastheadContext } from "../../context/MastheadContext";
import { useTown } from "../../context/TownContext";
import NewsletterSignupButton from "../Masterhead/LayoutsByVariant/Default/MasterheadDesktop/DefaultButtons/NewsletterSignupButton/NewsletterSignupButton";
import { useMasthead } from "../../hooks/useMasthead";

const GRAY = "#9C9C9C";

const TopNavBar = ({
  townSlug,
  displayWeather,
  isWeatherClickable,
  sponsorInfo,
  section,
  currentUser,
  isPathUserSignIn,
  isIframe,
}) => {
  const [isTownPickerOpen, setIsTownPickerOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [newsletterSignupButton, setNewsletterSignupButton] = useState();
  const { town, signUpPath, firstBlockButtons } = useTownTheme();
  const { mastheadInfo, townsWithClusters: towns } = useMastheadContext();
  const { nearbyTowns } = useTown();
  const { openHamburgerMenu, handleOpenMenu } = useMasthead();
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const containerWidth = useMemo(() => {
    if (isMobile) {
      return { left: 2, center: 4 };
    }
    return { left: 3, center: 4 };
  }, [isMobile]);
  const messageToParent = useMemo(() => {
    if (isMobile) {
      return { opened: "opened", closed: "closed" };
    }
    return { opened: "opened-desktop", closed: "closed-desktop" };
  }, [isMobile]);

  const toggleMenu = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleOpenMenu();
  };

  const handleComboboxForIframe = (state) => {
    setIsTownPickerOpen(state);
  };

  const handleSearchOnEnter = (key) => {
    if (key === "Enter" || key === "13") {
      handleSearch(town, searchText);
    }
  };

  useEffect(() => {
    setNewsletterSignupButton(
      firstBlockButtons.find((button) => button.action.includes("signup"))
    );

    return () => setNewsletterSignupButton();
  }, [firstBlockButtons]);

  useEffect(() => {
    const message =
      openHamburgerMenu || isTownPickerOpen
        ? messageToParent.opened
        : messageToParent.closed;
    sendMessageToParent(
      message,
      urlToUseOnIframe(town?.slug, withConfig("HOME_MONGO"))
    );
  }, [openHamburgerMenu, isTownPickerOpen, messageToParent]);

  if (isMobile) {
    return (
      <Grid
        justifyContent="space-between"
        container
        classes={{
          root: clsx(classes.top_nav_bar, isIframe && classes.no_padding),
        }}
      >
        {displayWeather && (
          <Grid item className={classes.grid_item}>
            {sponsorInfo && <Sponsor sponsor_info={sponsorInfo} />}
            <Weather
              townSlug={town?.slug}
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
              clickable={isWeatherClickable}
              reduced={sponsorInfo ? true : false}
            />
          </Grid>
        )}

        {newsletterSignupButton && (
          <NewsletterSignupButton
            button={newsletterSignupButton}
            signUpPath={signUpPath}
            className={classes.signup}
          />
        )}
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="space-evenly"
      spacing={2}
      classes={{
        root: clsx(classes.top_nav_bar, classes.no_printing),
      }}
    >
      <Grid item xs={containerWidth.left} className={classes.grid_item}>
        <div className={classes.icons_container}>
          <ButtonIcon
            className={classes.button_icon}
            children={<Home className={classes.icon} />}
            onClick={() => redirectToHomepage(town?.slug)}
            ariaLabel="Home"
          />

          <MenuButton toggleMenu={toggleMenu} useThemeColors={false} />
        </div>

        {mastheadInfo?.rss_url && (
          <Link
            children={<SquareRssFeed fillColor={primary} />}
            url={mastheadInfo.rss_url}
            target="_blank"
          />
        )}

        {displayWeather && (
          <>
            {sponsorInfo && <Sponsor sponsor_info={sponsorInfo} />}
            <Weather
              townSlug={townSlug}
              clickable={isWeatherClickable}
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
            />
          </>
        )}
      </Grid>

      <Grid item xs={containerWidth.center} className={classes.grid_item}>
        <TownPickerWithNearbyTowns
          nearbyTowns={nearbyTowns}
          town={town}
          towns={towns}
          handleComboboxForIframe={handleComboboxForIframe}
        />

        <SearchField
          placeholder={labels.SEARCH}
          onChange={setSearchText}
          startIcon
          startIconFill={GRAY}
          onKeyPress={handleSearchOnEnter}
          classes={{
            text_field: classes.search_text_field,
            start_icon: classes.textfield_search_icon,
          }}
        />
      </Grid>

      <Grid item xs={3} className={classes.grid_item}>
        <CustomButtons />
      </Grid>

      <HamburgerMenu
        openState={openHamburgerMenu}
        toggleMenu={toggleMenu}
        townSlug={townSlug}
        sectionSlug={section?.slug}
        currentUser={currentUser}
        isPathUserSignIn={isPathUserSignIn}
      />
    </Grid>
  );
};

TopNavBar.propTypes = {
  townSlug: string,
  displayWeather: bool,
  isWeatherClickable: bool,
  sponsorInfo: object,
  section: object,
  currentUser: object,
  isPathUserSignIn: bool,
};

export default TopNavBar;
