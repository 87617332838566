import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../config/labels";
import { useStyles } from "./TownPicker.style";
import ComboBox from "../../common/ComboBox/ComboBox";
import TagAutocomplete from "../../common/TagAutocomplete/TagAutocomplete";
import NearbyTowns from "./NearbyTowns/NearbyTowns";
import Typography from "../../common/Typography/Typography";
import { redirectToPage } from "../../utils/helper";
import withConfig from "../../utils/withConfig";
import Button from "../../common/Button/Button";

const NO_TOWN_OPTION = {
  id: 0,
  label: labels.DONT_SEE_YOUR_TOWN_CLICK_HERE_FOR_MORE_INFO,
  action: () => redirectToPage(withConfig("FRANCHISE_TAPINTO"), "_top"),
  labelComponent: (
    <Typography level="t2_text_2" color="tapintoOrange" bold>
      {labels.DONT_SEE_YOUR_TOWN_CLICK_HERE_FOR_MORE_INFO}
    </Typography>
  ),
};

const TownPicker = ({
  townName,
  nearbyTowns,
  isMultipleWithTags,
  isTownPickerMobile,
  dropdownAutocompleteClass,
  multipleTownPickerClass,
  townsAsItems,
  disableMultiplePicker,
  setItem,
  setTagsArray,
  containerClassName,
  comboBoxClassName,
  startIcon,
  placeholder,
  label,
  handleOpen,
  handleClose,
  valueforMultiplePicker,
  shouldRenderLabelComponentForMultiple,
  comboBoxClasses,
  onComboboxInputChange,
  withNoTownOption,
  defaultValue,
  tagAutocompleteClasses,
}) => {
  const classes = useStyles();
  const [openNearbyTowns, setOpenNearbyTowns] = useState(false);

  const toggleNearbyTowns = () => {
    setOpenNearbyTowns((openNearbyTowns) => !openNearbyTowns);
  };

  const closeNearbyTowns = () => {
    setOpenNearbyTowns(false);
  };

  return (
    <>
      {!isMultipleWithTags && (
        <div
          className={
            isTownPickerMobile
              ? classes.dropdown_towns_container
              : containerClassName
          }
          onClick={closeNearbyTowns}
        >
          <ComboBox
            classes={comboBoxClasses}
            withDropdown={false}
            freeSolo={false}
            className={
              isTownPickerMobile
                ? classes.dropdown_input
                : dropdownAutocompleteClass
                ? dropdownAutocompleteClass
                : comboBoxClassName
            }
            items={townsAsItems}
            isMultiple={isMultipleWithTags}
            setItem={setItem}
            label={label}
            placeholder={placeholder}
            startIcon={startIcon}
            handleOpen={handleOpen}
            handleClose={handleClose}
            onInputChange={onComboboxInputChange}
            {...(withNoTownOption && { stickyOption: NO_TOWN_OPTION })}
            defaultValue={defaultValue}
          />
        </div>
      )}
      {!isMultipleWithTags && townName && nearbyTowns?.length > 0 && (
        <div>
          <Button
            level="simple_button"
            variant="text"
            classes={{ root: classes.button, label: classes.button_label }}
            onClick={() => toggleNearbyTowns()}
            children={labels.TAPINTO_NEARBY}
          />
          <div
            className={clsx(
              openNearbyTowns && classes.tapinto_nearby_container_open,
              classes.tapinto_nearby_container_closed
            )}
          >
            {openNearbyTowns && <NearbyTowns towns={nearbyTowns} />}
          </div>
        </div>
      )}

      {isMultipleWithTags && (
        <TagAutocomplete
          className={
            multipleTownPickerClass
              ? multipleTownPickerClass
              : classes.multiple_with_tags
          }
          items={townsAsItems}
          disabled={disableMultiplePicker}
          setTagsArray={setTagsArray}
          value={valueforMultiplePicker}
          shouldRenderLabelComponent={shouldRenderLabelComponentForMultiple}
          placeholder={placeholder}
          classes={tagAutocompleteClasses}
        />
      )}
    </>
  );
};

TownPicker.propTypes = {
  nearbyTowns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  isMultipleWithTags: PropTypes.bool,
  isTownPickerMobile: PropTypes.bool,
  dropdownAutocompleteClass: PropTypes.any,
  townsAsItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      labelComponent: PropTypes.node,
    })
  ),
  disableMultiplePicker: PropTypes.bool,
  setItem: PropTypes.func,
  setTagsArray: PropTypes.func,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  valueforMultiplePicker: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      labelComponent: PropTypes.node,
    })
  ),
  shouldRenderLabelComponentForMultiple: PropTypes.bool,
  comboBoxClasses: PropTypes.object,
  onComboboxInputChange: PropTypes.func,
  withNoTownOption: PropTypes.bool,
  defaultValue: PropTypes.object,
  tagAutocompleteClasses: PropTypes.object,
};

TownPicker.defaultProps = {
  townsNamesWithoutMainTown: [],
  isMultipleWithTags: false,
  disableMultiplePicker: false,
  withNoTownOption: true,
};

export default TownPicker;
