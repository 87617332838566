import React from "react";
import clsx from "clsx";
import { useStyles } from "./OverallButtons.style";
import { redirectToPage } from "../../../../../utils/helper";
import labels from "../../../../../config/labels";
import Button from "../../../../../common/Button/Button";

const OverallButtons = ({ signUpLink, handleSubmitContentClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons_container}>
      <Button
        level="simple_button"
        color="primary"
        onClick={() => redirectToPage(signUpLink, "_top")}
        classes={{
          root: clsx(
            classes.button,
            classes.button_label,
            classes.signup_button
          ),
        }}
        ariaLabel="newsletter sign up"
      >
        {labels.NEWSLETTER_SIGNUP}
      </Button>

      <Button
        level="simple_button"
        color="primary"
        onClick={handleSubmitContentClick}
        classes={{
          root: clsx(
            classes.button,
            classes.button_label,
            classes.submit_content_button
          ),
        }}
        ariaLabel="submit content"
      >
        {labels.SUBMIT_CONTENT}
      </Button>
    </div>
  );
};

export default OverallButtons;
