import React from "react";
import PropTypes from "prop-types";
import { Chip, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useStyles } from "./ComboBox.style";

const ComboBox = ({
  label,
  placeholder,
  className,
  items,
  isMultiple,
  setItem,
  variant,
  defaultValue,
  shouldShowEndIcon,
  disabled,
  startIcon,
  handleOpen,
  handleClose,
  classes,
  onInputChange,
  stickyOption,
  fullWidth,
  onChange,
  error,
  helperText,
  value,
  freeSolo,
  groupBy,
  getOptionDisabled,
  withDropdown,
}) => {
  const classesStyle = useStyles();
  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state);

    if (!results.includes(stickyOption)) {
      results.push(stickyOption);
    }

    return results;
  };

  return (
    <Autocomplete
      {...(stickyOption && { filterOptions: filterOptions })}
      classes={classes}
      forcePopupIcon={shouldShowEndIcon}
      className={className}
      multiple={isMultiple}
      defaultValue={defaultValue}
      freeSolo={freeSolo}
      getOptionSelected={(option, value) => {
        if (!option?.value) {
          return option === value;
        }
        return option?.value === value?.value;
      }}
      fullWidth={fullWidth}
      options={items}
      disabled={disabled}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (Object.keys(option).length === 0) {
          return "";
        }
        return option.label || "";
      }}
      onInputChange={onInputChange}
      value={value}
      groupBy={groupBy}
      getOptionDisabled={getOptionDisabled}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label}
          placeholder={placeholder}
          variant={variant}
          InputProps={{
            ...props.InputProps,
            startAdornment: isMultiple
              ? props.InputProps.startAdornment
              : startIcon,
          }}
          inputProps={{
            ...props.inputProps,
            autoComplete: "none",
            className: !withDropdown
              ? classesStyle.input
              : classesStyle.input_dropdown,
          }}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (!isMultiple && option?.labelComponent) {
          return option.labelComponent;
        } else {
          return option?.label;
        }
      }}
      onChange={(event, item) => {
        event.stopPropagation();
        {
          if (item != null) {
            item?.action?.(), setItem(true);
            onChange && onChange(item);
          } else {
            setItem(false);
          }
        }
      }}
      {...(handleOpen &&
        handleClose && {
          onOpen: () => handleOpen(true),
          onClose: () => handleClose(false),
        })}
    />
  );
};

ComboBox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  InputProps: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func,
        labelComponent: PropTypes.object,
      }),
    ])
  ),
  isMultiple: PropTypes.bool,
  setItem: PropTypes.func,
  variant: PropTypes.string,
  defaultValue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  }),
  shouldShowEndIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  onInputChange: PropTypes.func,
  stickyOption: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    action: PropTypes.func,
    labelComponent: PropTypes.node,
  }),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.any,
  withDropdown: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

ComboBox.defaultProps = {
  setItem: () => "",
  variant: "outlined",
  shouldShowEndIcon: false,
  disabled: false,
  freeSolo: true,
  fullWidth: false,
};

export default ComboBox;
