import React, { useEffect, useState } from "react";

import PopUpDialog from "../../../../../../../common/Dialog/PopUpDialog";
import { handleModalActions } from "../../../../../../../utils/helper";
import LogInPopUp from "../../../../../../LogInPopUp/LogInPopUp";
import BubbledButton from "../../../../../../../common/Button/BubbledButton";
import { useStyles } from "./LoginButton.style";
import { useTownTheme } from "../../../../../../../context/TownThemeContext";
import withConfig from "../../../../../../../utils/withConfig";
import theme from "../../../../../../../common/Theme/themes/default";

const LoginButton = ({ button, townsWithClusters, className }) => {
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const { town } = useTownTheme();
  const openLoginByDefault =
    window.location.pathname.includes("/users/sign_in");
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setOpenLoginPopUp(openLoginByDefault);
    }, 800);

    return () => {
      setOpenLoginPopUp(false);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <PopUpDialog
        onClose={() => setOpenLoginPopUp(false)}
        openState={openLoginPopUp}
        title={""}
        content={
          town ? (
            <LogInPopUp
              townName={town.name}
              townsWithClusters={townsWithClusters}
              isPathUserSignIn={false}
              townSlug={town.slug}
            />
          ) : (
            <></>
          )
        }
        closeButtonColor={primary}
        titleClass={classes.login_dialog_title}
        closeButtonClass={classes.login_close_button}
        className={classes.login_popup}
        disableCloseOnBackDropClick={true}
      />
      <BubbledButton
        level="custom_button"
        className={className}
        backgroundColor={button.background_color}
        labelColor={button.label_color}
        onClick={() =>
          handleModalActions({
            type: "login",
            action: "open",
            setOpenModal: setOpenLoginPopUp,
            envUrl: withConfig("HOME"),
          })
        }
        dataCy="login"
      >
        {button.label}
      </BubbledButton>
    </>
  );
};

export default LoginButton;
