import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareTiktokBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <path
        d="M22.0648 12.3919V14.8042C21.6426 14.7629 21.0956 14.6677 20.4892 14.4452C19.6972 14.1551 19.108 13.7583 18.7225 13.4509V18.3276L18.7133 18.3124C18.7201 18.4086 18.7225 18.5073 18.7225 18.6065C18.7225 21.0286 16.7522 23 14.3291 23C11.9059 23 9.93555 21.0286 9.93555 18.6065C9.93555 16.1844 11.9059 14.212 14.3291 14.212C14.5657 14.212 14.7989 14.2312 15.0263 14.2675V16.6454C14.8078 16.5669 14.5735 16.5251 14.3291 16.5251C13.1821 16.5251 12.2482 17.4581 12.2482 18.606C12.2482 19.7539 13.1821 20.6869 14.3291 20.6869C15.4761 20.6869 16.41 19.753 16.41 18.606C16.41 18.5628 16.4089 18.5201 16.406 18.4769V9H18.8173C18.8262 9.20425 18.8345 9.4095 18.8433 9.61425C18.8596 10.0164 19.0029 10.4023 19.2518 10.7185C19.545 11.0902 19.9766 11.5218 20.5839 11.8665C21.1521 12.1885 21.6867 12.327 22.0648 12.3919Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareTiktokBlack;
