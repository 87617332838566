import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    breakpoints: { down: below, values },
    palette,
  } = theme;
  return {
    masthead_container: {
      alignItems: "flex-end",
      flexDirection: "column",
      backgroundColor: palette.dark.black,
      [below(values.md)]: {
        height: "fit-content",
      },
    },
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
    sticky_masthead: {
      transition: "transform ease 0.3s",
      position: "fixed",
      width: "100%",
      top: "0px",
      zIndex: 999,
    },
  };
});

export { useStyles };
