import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Grid from "../../../../../common/Grid/Grid";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import MenuButton from "../../../../../common/MenuButton/MenuButton";
import HamburgerMenu from "../../../../HamburgerMenu/HamburgerMenu";
import MasterheadLogo from "../MasterheadLogo";
import Sponsor from "../../../../Sponsor/Sponsor";
import { Weather } from "../../../../Weather/Weather";
import SearchField from "../../../../../common/SearchField/SearchField";
import labels from "../../../../../config/labels";
import { useStyles } from "./ReducedVersion.style";
import { handleSearch } from "../../../../../utils/helper";
import { bool, object, string } from "prop-types";
import NewsletterSignupButton from "../MasterheadDesktop/DefaultButtons/NewsletterSignupButton/NewsletterSignupButton";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import Search from "../../../../../common/Icons/Search";
import ButtonIcon from "../../../../../common/Button/ButtonIcon";
import { useMastheadContext } from "../../../../../context/MastheadContext";
import MobileSearch from "../../../../MobileSearch/MobileSearch";
import { useMasthead } from "../../../../../hooks/useMasthead";

const GRAY = "#9C9C9C";

const ReducedVersion = ({
  townSlug,
  section,
  currentUser,
  displayWeather,
  sponsorInfo,
  isWeatherClickable,
  legalNoticePermissions,
  isPathUserSignIn,
}) => {
  const classes = useStyles();
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [newsletterSignupButton, setNewsletterSignupButton] = useState();
  const [searchText, setSearchText] = useState("");
  const { mastheadInfo } = useMastheadContext();
  const { sticky, openHamburgerMenu, handleOpenMenu } = useMasthead();
  const { town, themeColors, firstBlockButtons, signUpPath } = useTownTheme();
  const isMobile = screenWidthIsLowerThan(values.md);
  const containerWidth = useMemo(() => {
    if (isMobile) {
      return { logo: 6, menu: 3 };
    }
    return { logo: 4, menu: 3 };
  }, [isMobile]);

  useEffect(() => {
    setNewsletterSignupButton(
      firstBlockButtons.find(
        (button) =>
          button.action.includes("signup") ||
          button.action.includes("subscription")
      )
    );

    return () => setNewsletterSignupButton();
  }, [firstBlockButtons]);

  useEffect(() => {
    if (openMobileSearch && !sticky) {
      setOpenMobileSearch(false);
    }
  }, [sticky, openMobileSearch]);

  const toggleMenu = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleOpenMenu();
  };

  const handleSearchOnEnter = (key) => {
    if (key === "Enter" || key === "13") {
      handleSearch(town, searchText);
    }
  };

  return (
    <Grid
      style={{
        backgroundColor: themeColors?.masthead_colors?.background,
      }}
      container
      justifyContent={isMobile ? "space-between" : "space-evenly"}
      className={clsx(classes.reduced_masterhead, classes.no_printing)}
    >
      <Grid
        className={clsx(
          classes.grid_item,
          isMobile && classes.mobile_menu_container
        )}
        item
        xs={containerWidth.menu}
      >
        <MenuButton toggleMenu={toggleMenu} useThemeColors={false} />
        {!isMobile && displayWeather && (
          <>
            {sponsorInfo && <Sponsor sponsor_info={sponsorInfo} />}
            <Weather
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
              townSlug={townSlug}
              clickable={isWeatherClickable}
              forStickyMasthead
            />
          </>
        )}
      </Grid>

      <Grid className={classes.grid_item} item xs={containerWidth.logo}>
        <MasterheadLogo
          townName={mastheadInfo?.name_for_header}
          townSlug={townSlug}
          sectionSlug={section?.slug}
          isLicense={town?.under_license_contract}
          displaySlogan={false}
          logoUrl={
            town?.slug === "halston-media"
              ? "https://tapinto-production.s3.amazonaws.com/assets/images/halston-media-reduced-logo.png"
              : mastheadInfo?.logo_image
          }
          reduced
        />
      </Grid>

      {isMobile ? (
        <Grid className={classes.grid_item} item xs={3}>
          <ButtonIcon
            children={<Search />}
            className={classes.button_icon}
            onClick={() => setOpenMobileSearch((prev) => !prev)}
          />
          {displayWeather && (
            <Weather
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
              townSlug={townSlug}
              clickable={isWeatherClickable}
              reduced
            />
          )}
        </Grid>
      ) : (
        <Grid className={classes.grid_item} item xs={4}>
          <SearchField
            placeholder={labels.SEARCH}
            onChange={setSearchText}
            startIcon
            startIconFill={GRAY}
            onKeyPress={handleSearchOnEnter}
            classes={{
              text_field: classes.search_text_field,
              start_icon: classes.textfield_search_icon,
            }}
          />

          {newsletterSignupButton && (
            <NewsletterSignupButton
              button={newsletterSignupButton}
              signUpPath={signUpPath}
              className={classes.signup}
            />
          )}
        </Grid>
      )}

      <HamburgerMenu
        openState={openHamburgerMenu}
        toggleMenu={toggleMenu}
        townSlug={townSlug}
        sectionSlug={section?.slug}
        currentUser={currentUser}
        legalNoticePermissions={legalNoticePermissions}
        isPathUserSignIn={isPathUserSignIn}
      />

      {isMobile && <MobileSearch openState={openMobileSearch} />}
    </Grid>
  );
};

ReducedVersion.propTypes = {
  townSlug: string,
  section: object,
  currentUser: object,
  displayWeather: bool,
  sponsorInfo: object,
  isWeatherClickable: bool,
  legalNoticePermissions: object,
  isPathUserSignIn: bool,
};

export default ReducedVersion;
