import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./Header.style";
import labels from "../../../config/labels";
import Link from "../../../common/Link/Link";
import { topLocalNewstUrl } from "../../../utils/helper";

const Header = ({ town, section, subsection }) => {
  const classes = useStyles();
  const [sectionHeader, setSectionHeader] = useState();
  const [subsectionHeader, setSubsectionHeader] = useState();

  const Title = ({ link }) => {
    useEffect(() => {
      if (section && subsection) {
        setSectionHeader(section.name);
        setSubsectionHeader(subsection.name);
      }
    }, [subsection, section]);

    const label = () => {
      if (subsection){
        return subsection.name
      }
      else if (section) {
        return section.name;
      } else if (town) {
        return town?.options?.local_news_header;
      }
      return labels.LOCAL_NEWS_FROM_AROUND_THE_TAP_INTO_NETWORK;
    };

    return link ? (
      sectionHeader && subsectionHeader ? (
        <>
          <span className={classes.section_header}>{sectionHeader}</span>
          <span className={classes.section_header}>{` > `}</span>
          <span className={classes.subsection_header}>{subsectionHeader}</span>
        </>
      ) : (
        label()
      )
    ) : (
      <Typography
        level="h2_header_2"
        bold
        className={clsx(classes.header_text, !town && classes.capitalized_text)}
      >
        {sectionHeader && subsectionHeader ? (
          <>
            <span className={classes.section_header}>{sectionHeader}</span>
            <span className={classes.section_header}>{` > `}</span>
            <span className={classes.subsection_header}>
              {subsectionHeader}
            </span>
          </>
        ) : (
          label()
        )}
      </Typography>
    );
  };

  return (
    <div className={classes.header_container}>
      {town && section === undefined ? (
        <Link
          level="t5_text_5"
          className={classes.card_title}
          children={<Title link={true} />}
          url={topLocalNewstUrl({
            townSlug: town.slug,
          })}
          bold
          underlined
        />
      ) : (
        <Title link={false} />
      )}
    </div>
  );
};

export default Header;
