import React from "react";
import Box from "../../../../../../common/Box/Box";
import useStyles from "./FourCards.style";
import HorizontalLayout from "../../../../../../common/Card/Layouts/Horizontal/HorizontalLayout";
import ContentInfo from "../../../../Shared/ContentInfo/ContentInfo";
import { screenWidthIsLowerThan } from "../../../../../../hooks/useMediaQuery";
import VerticalLayout from "../../../../../../common/Card/Layouts/Vertical/VerticalLayout";
import Card from "../../../../../../common/Card/Card";
import Divider from "../../../../../../common/Divider/Divider";
import { NYVT_MEDIA_SLUG } from "../../../../../../utils/constants/licenseConstants";

const SingleContent = ({
  content,
  blockClass,
  withBottomBorder = false,
  town = null,
}) => {
  const isMobile = screenWidthIsLowerThan(768);
  const classes = useStyles({ withBottomBorder });
  return isMobile ? (
    <Card withShadow={false}>
      <VerticalLayout
        media={content.media_content[0]}
        information={
          <ContentInfo
            content={content}
            blockClass={blockClass}
            asContentHeader={true}
          />
        }
        options={{
          caption: { visible: false },
          credits: { visible: false },
          media: {
            clickable: true,
            townContentUrl: content.url_content,
            opacityOnFocus: true,
          },
          videoPlayer: {
            width: "100%",
            height: "170px",
            showPlayable: true,
          },
        }}
        customClasses={{
          image: classes.image,
          media_container: classes.media_container,
        }}
      />
    </Card>
  ) : (
    <HorizontalLayout
      media={content.media_content[0]}
      information={
        <ContentInfo
          withTruncatedDescription
          content={content}
          asContentHeader
          blockClass={blockClass}
          lineClamp={4}
        />
      }
      options={{
        media: {
          clickable: true,
          townContentUrl: content.url_content,
          opacityOnFocus: true,
        },
        videoPlayer: {
          width: "100%",
          height: "122px",
          showPlayable: true,
        },
      }}
      customClasses={{
        information_container: classes.information_container,
        root: classes.root,
        image:
          town?.slug != NYVT_MEDIA_SLUG
            ? classes.horizontal_image
            : classes.horizontal_image_license,
        media_link:
          town?.slug != NYVT_MEDIA_SLUG
            ? classes.media_container_link
            : classes.media_container_link_license,
        media_and_information_container: classes.media_information_container,
      }}
    />
  );
};

const FourCards = ({ contents, blockClass, town = null }) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(768);
  const halfContents = contents.slice(0, 2);
  const halfContents2 = contents.slice(2, 4);

  return (
    <Box className={classes.four_cards_container}>
      <Box className={classes.half_content}>
        {halfContents.map((content, index) => {
          return (
            <>
              <SingleContent
                content={content}
                key={index}
                blockClass={blockClass}
                withBottomBorder={index === 0}
                town={town}
              />
              {index == 0 && <Divider orientation="horizontal" />}
            </>
          );
        })}
      </Box>
      {!isMobile && <Divider orientation="vertical" flexItem />}
      <Box className={classes.half_content}>
        {halfContents2.map((content, index) => {
          return (
            <>
              <SingleContent
                content={content}
                key={index}
                blockClass={blockClass}
                withBottomBorder={index === 0}
                town={town}
              />
              {index == 0 && <Divider orientation="horizontal" />}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default FourCards;
