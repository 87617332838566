import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    logo_img_container: {
      display: "block",
      height: "100%",
      maxHeight: spacing(10),
      width: "fit-content",
    },
    logo_img: {
      maxHeight: spacing(10),
    },
    masterhead_link: {
      textDecoration: "none",
      textColor: "inherit",
    },
    overall_logo_link: {
      maxWidth: "100%",
      [below(values.md)]: {
        margin: "auto",
      },
    },
    header: {
      fontSize: "2.6em !important",
      [below(values.md)]: {
        fontSize: "1.2em !important",
      },
    },
    masterhead_link: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    reduced_logo_padding: {
      padding: `${spacing(1)}px 0px`,
      height: "100%",

      [below(values.xs)]: {
        padding: `${spacing(1)}px !important`,
      },
    },
    reduced_logo_img: {
      maxHeight: "100%",
    },
    reduced_header: {
      fontSize: "2em !important",
      [below(values.md)]: {
        fontSize: "16px !important",
      },
    },
    town_name_logo_grid: {
      lineHeight: "2.6em",
      [below(values.md)]: {
        lineHeight: "16px",
      },
    },
    margin_right: {
      marginRight: spacing(1),
    },
  };
});

export { useStyles };
