import React from "react";

export const Ice = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M5.00958 27.1949H7.36102V24.8435H5.00958V27.1949ZM14.3131 30.3642L16.0511 32L17.7891 30.3642L16.0511 28.6262L14.3131 30.3642ZM1.63578 14.4153L0 16.1534L1.73802 17.7891L3.3738 16.1534L1.63578 14.4153ZM24.639 27.1949H26.9904V24.8435H24.639V27.1949ZM7.25879 4.90735H4.90735V7.25879H7.25879V4.90735ZM27.1949 4.90735H24.8435V7.25879H27.1949V4.90735ZM17.6869 1.63578L15.9489 0L14.3131 1.63578L16.0511 3.27157L17.6869 1.63578ZM29.4441 19.7316L27.9105 16.0511L29.4441 12.5751L26.5815 15.5399H21.9808L23.6166 12.984L20.6518 12.4728L23.8211 9.20128H27.8083L24.23 7.8722L22.901 4.19169V8.17891L19.7316 11.4505L19.2204 8.28115L16.5623 10.0192V5.72524L19.4249 2.76038L16.0511 4.4984L12.4728 2.96486L15.3355 5.82748V10.2236L12.6773 8.58786L12.2684 11.5527L9.09904 8.17891V4.19169L7.8722 7.76997L4.08946 9.20128H8.17891L11.3482 12.3706L8.07668 13.0863L10.1214 15.4377H5.52077L2.65815 12.5751L4.08946 16.1534L2.76038 19.7316L5.623 16.7668H10.2236L8.17891 19.4249L11.4505 19.9361L8.17891 23.1054H4.08946L7.8722 24.4345L9.09904 28.0128V24.0256L12.1661 20.9585L12.7796 24.1278L15.4377 22.1853V26.7859L12.5751 29.5463L16.0511 28.0128L19.5272 29.5463L16.7668 26.7859V22.1853L19.4249 24.0256L19.9361 20.8562L23.1054 24.1278L23.2077 28.115L24.3323 24.639L28.0128 23.3099H24.0256L20.8562 20.2428L23.9233 19.3227L22.0831 16.9712H26.5815L29.4441 19.7316ZM12.5751 13.6997L14.5176 15.5399H11.8594L12.5751 13.6997ZM12.6773 18.7093L11.8594 16.7668H14.5176L12.6773 18.7093ZM15.4377 20.2428L13.6997 19.5272L15.4377 17.5847V20.2428ZM15.4377 14.6198L13.6997 12.7796L15.4377 11.9617V14.6198ZM19.5272 13.6997L20.2428 15.5399H17.5847L19.5272 13.6997ZM16.7668 11.9617L18.607 12.7796L16.7668 14.6198V11.9617ZM16.7668 20.345V17.5847L18.607 19.4249L16.7668 20.345ZM19.4249 18.7093L17.5847 16.7668H20.345L19.4249 18.7093ZM30.1597 14.4153L28.4217 16.0511L30.1597 17.6869L31.8978 16.0511L30.1597 14.4153Z"
        fill={color}
      />
    </svg>
  );
};
