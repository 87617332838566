import { makeStyles } from "../Theme/Theme";

const definedStyles = {
  h1_header_1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "35px !important",
    fontStyle: "normal",
    fontWeight: "500",
  },
  h1_header_1_mobile: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "30px !important",
    fontStyle: "normal",
    fontWeight: "500",
    marginBottom: 0,
  },
  h2_header_2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 26,
    fontStyle: "normal",
    fontWeight: 500,
  },
  h2_header_2_mobile: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
  },
  h3_sub_header: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 500,
  },
  h3_sub_header_2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "26px",
  },
  h4_sub_header_4: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    marginBottom: 0,
  },
  h5_sub_header_2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 500,
  },
  paragraph_1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: 500,
  },

  paragraph_2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
  },

  t0_text_0: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 500,
  },
  t1_text_1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
  },
  t2_text_2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 500,
  },
  t3_text_3: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
  },
  cms_form_label: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
  },
  t4_text_4: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
  },
  t5_text_5: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 500,
  },
  inherit_font: {
    fontSize: "inherit",
  },
  common: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  mont_light: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 200,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  mont_italic: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "italic",
    fontWeight: 200,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  mont_medium: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    marginBlockStart: 0,
  },

  mont_bold: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  h1: {
    fontWeight: 800,
    fontSize: "48px",
    lineHeight: "1.1",
  },
  h2: {
    fontWeight: "bold",
    fontSize: "38px",
    lineHeight: "1.1",
  },
  h3: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "1.1",
  },
  h4: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "1.45",
  },
  body: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "1.45",
  },
  large: {
    fontWeight: "normal",
    fontSize: "60px",
    lineHeight: "1.45",
  },
  small: {
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "1.45",
  },
  tiny: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "1.6",
  },
  underlined: {
    textDecoration: "underline",
  },
  font_size_9_px: {
    fontSize: "9px",
  },
  font_size_10_px: {
    fontSize: "10px",
  },
  font_size_11_px: {
    fontSize: "11px",
  },
  font_size_12_px: {
    fontSize: "12px",
  },
  font_size_13_px: {
    fontSize: "13px",
  },
  font_size_15_px: {
    fontSize: "15px",
  },
  font_size_16_px: {
    fontSize: "16px",
  },
  font_size_17_px: {
    fontSize: "17px",
  },
  font_size_18_px: {
    fontSize: "18px",
  },
  font_size_20_px: {
    fontSize: "20px",
  },

  font_size_26_px: {
    fontSize: "26px",
  },
  font_size_28_px: {
    fontSize: "28px",
  },
  font_size_35_px: {
    fontSize: "35px",
  },
  font_size_32_px: {
    fontSize: "32px",
    fontWeight: "bold",
  },
  centered: {
    textAlign: "center",
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
};

const useStyles = makeStyles(() => {
  return definedStyles;
});

export { useStyles, definedStyles };
