import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  townpicker_container: {
    height: theme.spacing(4),
    width: "100%",
  },
  townpicker: {
    backgroundColor: theme.palette.miscellaneous.white,
    borderRadius: "4px",
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
    },
    "& .MuiInputBase-root": {
      height: `${theme.spacing(4)}px !important`,
      padding: "0px 0px 0px 8px !important",
    },
    "& input": {
      padding: "0px 0px 0px 8px !important",
      boxShadow: "none !important",
      border: "none !important",
      fontSize: "12px",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: theme.spacing(1),
    },
  },
  nearby_towns_icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(3.5),
  },
  textfield_search_icon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  nearby_towns_button: {
    position: "unset",
    "&.MuiIconButton-root": {
      padding: 0,
    },
  },
  title: {
    "&.MuiDialogTitle-root": {
      height: theme.spacing(6),
      width: theme.spacing(50),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: theme.spacing(1),
      margin: 0,
      background: theme.palette.themeColor.primary,
    },
  },
  close_button: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: "pointer",
  },
  popup_title_typography_class: {
    fontSize: "17px !important",
  },
  nearby_towns_content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export { useStyles };
