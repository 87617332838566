import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareLinkedin = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <path
        d="M28.937 0H3.06302C1.37136 0 0 1.37136 0 3.06302V28.937C0 30.6286 1.37136 32 3.06302 32H28.937C30.6286 32 32 30.6286 32 28.937V3.06302C32 1.37136 30.6286 0 28.937 0Z"
        fill="#0B86CA"
      />
      <path
        d="M9.2328 12.3315H12.5581V23.061H9.2328V12.3315ZM10.9287 7C11.3097 7.00001 11.6821 7.11284 11.999 7.32428C12.3159 7.53572 12.5631 7.83628 12.7094 8.18805C12.8557 8.53982 12.8946 8.92703 12.821 9.30085C12.7475 9.67466 12.5649 10.0183 12.2963 10.2885C12.0277 10.5587 11.6851 10.7432 11.3117 10.8189C10.9383 10.8945 10.5509 10.8579 10.1983 10.7136C9.84566 10.5694 9.54369 10.3239 9.33044 10.0082C9.11719 9.69249 9.00222 9.3207 9.00003 8.93973C8.99857 8.68552 9.04738 8.43354 9.14365 8.19826C9.23992 7.96299 9.38175 7.74907 9.56099 7.5688C9.74022 7.38853 9.95333 7.24548 10.188 7.14786C10.4228 7.05025 10.6745 7 10.9287 7ZM14.6862 12.3315H17.8895V13.7946C18.2102 13.2474 18.6734 12.7975 19.2298 12.4929C19.7861 12.1884 20.4148 12.0407 21.0485 12.0655C24.3738 12.0655 25.0499 14.2823 25.0499 17.1753V23.061H21.7247V17.8736C21.7247 16.6321 21.7247 15.0249 19.9955 15.0249C18.2664 15.0249 18.0004 16.3772 18.0004 17.7738V23.0831H14.6751L14.6862 12.3315Z"
        fill="white"
      />
    </svg>
  );
};

export default SquareLinkedin;
