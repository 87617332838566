import React from "react";
export const Windy = ({color = 'white'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="32"
      viewBox="0 0 52 32"
      fill="none"
    >
      <path
        d="M21.7358 18.4151C25.9623 18.4151 30.3396 18.4151 34.566 18.4151C38.0377 18.4151 40.7547 20.9811 41.2075 24.3019C41.6604 27.4717 39.6981 30.6415 36.5283 31.5471C33.3585 32.4528 29.8868 31.0943 28.5283 28.0754C28.2264 27.3207 27.9245 26.4151 27.9245 25.6604C27.9245 25.5094 27.9245 25.5094 27.9245 25.3585C28.2264 24.6038 29.283 24.3019 29.7359 25.0566C29.8868 25.2075 29.8868 25.3585 29.8868 25.5094C30.4906 28.0754 31.8491 29.5849 34.1132 29.7358C36.6792 29.8868 38.7924 28.0754 38.9434 25.5094C39.0943 23.0943 37.1321 20.8302 34.566 20.8302C30.9434 20.6792 27.1698 20.8302 23.5472 20.8302C18.717 20.8302 13.7358 20.8302 8.90566 20.8302C8.15094 20.8302 6.9434 21.1321 6.79245 19.7736C6.79245 18.4151 7.84906 18.566 8.75472 18.566C13.1321 18.4151 17.5094 18.4151 21.7358 18.4151Z"
        fill={color}
      />
      <path
        d="M14.3396 13.4339C11.3208 13.4339 8.30189 13.4339 5.28302 13.4339H5.13208C4.07547 13.283 4.07547 11.6226 5.13208 11.3207H5.28302C11.1698 11.1698 16.9057 11.3207 22.7925 11.3207C24.9057 11.3207 26.717 10.1132 27.1698 8.30186C27.7736 6.49054 27.3208 4.83017 25.8113 3.47168C24.6038 2.26413 23.0943 1.96224 21.434 2.41507C19.9245 3.01885 18.8679 4.22639 18.566 6.03771C18.4151 6.94337 18.4151 7.84903 17.2075 7.69809C15.8491 7.54715 16.3019 6.3396 16.4528 5.58488C17.2075 1.96224 20.3774 -0.301909 24 0.150921C27.4717 0.603752 29.8868 3.62262 29.7359 7.24526C29.5849 10.717 26.717 13.4339 23.0943 13.4339C22.9434 13.4339 22.7925 13.4339 22.7925 13.4339C19.9245 13.4339 17.2075 13.4339 14.3396 13.4339Z"
        fill={color}
      />
      <path
        d="M49.6604 4.67922C47.6981 2.41507 44.3774 1.66036 41.6604 2.56602C38.7925 3.62262 36.6792 6.18866 36.6792 9.05658C36.6792 9.20753 36.6792 9.20752 36.6792 9.35847C36.9811 10.2641 38.1887 10.4151 38.7925 9.66035C38.9434 9.50941 38.9434 9.50941 38.9434 9.35847C39.2453 9.20752 39.2453 8.75469 39.2453 8.45281C40 5.58488 42.717 4.07545 45.434 4.83017C48 5.43394 49.5094 8.15092 48.9057 10.8679C48.4528 13.283 46.3396 14.7924 43.1698 14.7924C29.7358 14.7924 16.3019 14.7924 3.01887 14.7924C2.41509 14.7924 1.66038 14.7924 1.0566 14.7924H0.90566C-0.301887 15.0943 -0.301887 16.7547 0.90566 17.0566H1.0566C1.66038 17.0566 2.41509 17.0566 3.01887 17.0566C3.16981 17.0566 3.4717 17.0566 3.62264 17.0566C9.96226 17.0566 16.3019 17.0566 22.6415 17.0566C29.7358 17.0566 36.8302 17.0566 43.9245 17.0566C47.2453 17.0566 49.6604 15.5471 51.0189 12.6792C52.2264 9.96224 51.7736 7.09432 49.6604 4.67922Z"
        fill={color}
      />
    </svg>
  );
};
