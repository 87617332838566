import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    palette,
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    login_item: {
      justifyContent: "space-between !important",
      backgroundColor: "#474747 !important",
      borderRadius: `${spacing(2)}px !important`
    },
    logout_item: {
      justifyContent: "space-between !important",
      marginTop: `${spacing(1)}px !important`,
      backgroundColor: "#474747 !important",
      borderRadius: `${spacing(2)}px !important`
    },
    first_admin_item: {
      borderTop: `1px solid ${palette.dark.gray} !important`,
    },
    menu_item_gutters: {
      paddingRight: `${theme.spacing(2)}px !important`,
      paddingLeft: `${theme.spacing(1)}px !important`,
    },
    admin_list: {
      paddingRight: `${spacing(4)}px !important`,
      marginTop: `${spacing(3)}px !important`,
    },
    login_dialog_title: {
      "&.MuiDialogTitle-root": {
        height: 0,
        padding: 0,
      },
    },
    login_close_button: {
      width: spacing(3),
      height: spacing(3),
      position: "absolute",
      right: spacing(2),
      top: spacing(2),
      cursor: "pointer",
    },
    login_popup: {
      zIndex: "94037 !important",
      marginTop: "0px !important",
      [below(values.md)]: {
        zIndex: "94036 !important",
      },
    },
  };
});

export { useStyles };
