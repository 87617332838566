import React, { useState, useEffect } from "react";
import { bool, object, string } from "prop-types";
import clsx from "clsx";
import { useStyles } from "./TopNavBar.style";
import withConfig from "../../utils/withConfig";
import HamburgerMenu from "../../components/HamburgerMenu/HamburgerMenu";
import { sendMessageToParent } from "../../utils/helper";
import { urlToUseOnIframe } from "../../utils/halstonMediaHelper";
import { useTownTheme } from "../../context/TownThemeContext";
import Grid from "../../common/Grid/Grid";
import TownPickerWithNearbyTowns from "./TownPickerWithNearbyTowns/TownPickerWithNearbyTowns";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import values from "../../common/Theme/themes/breakpoints/values";
import theme from "../../common/Theme/themes/default";
import { useMemo } from "react";
import { useMastheadContext } from "../../context/MastheadContext";
import { useMasthead } from "../../hooks/useMasthead";
import ArrowDownIcon from "../../common/Icons/ArrowDown";

const GRAY = "#9C9C9C";

const TopNavBar = ({
  townSlug,
  section,
  currentUser,
  isPathUserSignIn,
  origin = "Default",
}) => {
  const [submenuOnlySections, setSubmenuOnlySections] = useState([]);
  const [submenuSectionsWithSubsections, setSubmenuSectionsWithSubsections] =
    useState([]);
  const [submenus, setSubmenus] = useState();
  const [idOpen, setIdOpen] = useState();
  const [isTownPickerOpen, setIsTownPickerOpen] = useState(false);
  const { town } = useTownTheme();
  const { hamburgerMenuItems, townsWithClusters: towns } = useMastheadContext();
  const { openHamburgerMenu, handleOpenMenu } = useMasthead();
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const messageToParent = useMemo(() => {
    if (isMobile) {
      return { opened: "opened", closed: "closed" };
    }
    return { opened: "opened-desktop", closed: "closed-desktop" };
  }, [isMobile]);

  const toggleMenu = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleOpenMenu();
  };

  const handleComboboxForIframe = (state) => {
    setIsTownPickerOpen(state);
  };

  const handleSubmenuDisplay = (items, id) => {
    const presentElement = document.getElementById(`menu-list-${origin}-${id}`);
    const lastElement = document.getElementById(
      `menu-list-${origin}-${idOpen}`
    );
    lastElement?.classList.remove(classes.menu_list_choose);

    presentElement?.classList.add(classes.menu_list_choose);
    setSubmenus(items);
    setIdOpen(id);
  };

  useEffect(() => {
    const message =
      openHamburgerMenu || isTownPickerOpen
        ? messageToParent.opened
        : messageToParent.closed;
    sendMessageToParent(
      message,
      urlToUseOnIframe(town?.slug, withConfig("HOME_MONGO"))
    );
  }, [openHamburgerMenu, isTownPickerOpen, messageToParent]);

  useEffect(() => {
    const element = document.getElementById(`contList${origin}`);
    const arrow = document.getElementById(`shadowContainer${origin}`);
    element.addEventListener("wheel", function (event) {
      this.scrollBy({
        left: event.deltaY < 0 ? -120 : 120,
      });
      event.preventDefault();
      if (this.scrollLeft >= this.scrollWidth - this.clientWidth - 1) {
        arrow.style.display = "none";
      } else {
        arrow.style.display = "block";
      }
    });
  }, []);

  const handleOnLeaveNavbar = () => {
    const lastElement = document.getElementById(
      `menu-list-${origin}-${idOpen}`
    );
    lastElement?.classList.remove(classes.menu_list_choose);
    setSubmenus([]);
    setIdOpen("");
  };

  useEffect(() => {
    if (submenus) {
      setSubmenuOnlySections(
        submenus.filter((submenu) => submenu.submenu_items.length == 0)
      );
      setSubmenuSectionsWithSubsections(
        submenus.filter((submenu) => submenu.submenu_items.length > 0)
      );
    }
  }, [submenus]);

  return (
    <div onMouseLeave={handleOnLeaveNavbar}>
      <Grid
        container
        justifyContent="space-between"
        classes={{
          root: clsx(classes.top_nav_bar, classes.no_printing),
        }}
      >
        <Grid item xs={10} className={classes.grid_item}>
          <ul id={"contList" + origin} className={classes.list_container}>
            {hamburgerMenuItems.map((item, index) => {
              if (item.submenu_items.length > 0) {
                return (
                  <li
                    id={`menu-list-${origin}-${item.id}`}
                    className={classes.menu_drop_down_item}
                    onMouseEnter={() =>
                      handleSubmenuDisplay(item.submenu_items, item.id)
                    }
                    key={index}
                  >
                    {item.name}
                    <ArrowDownIcon />
                  </li>
                );
              } else {
                return (
                  <li className={classes.menu_item} key={index}>
                    <a href={item.path}>{item.name}</a>
                  </li>
                );
              }
            })}
          </ul>
          <div
            id={"shadowContainer" + origin}
            className={classes.container_shadow}
          ></div>
        </Grid>

        <Grid
          container
          item
          xs={2}
          className={classes.grid_item}
          justifyContent={"flex-end"}
        >
          <TownPickerWithNearbyTowns
            town={town}
            towns={towns}
            handleComboboxForIframe={handleComboboxForIframe}
          />
        </Grid>

        <HamburgerMenu
          openState={openHamburgerMenu}
          toggleMenu={toggleMenu}
          townSlug={townSlug}
          sectionSlug={section?.slug}
          currentUser={currentUser}
          isPathUserSignIn={isPathUserSignIn}
        />
      </Grid>

      {submenus?.length > 0 && (
        <Grid
          container
          justifyContent="space-between"
          classes={{
            root: clsx(classes.top_nav_bar_section, classes.no_printing),
          }}
          item
          xs={12}
        >
          <Grid item xs={10} className={classes.lists_sections}>
            {submenuOnlySections.length > 0 && (
              <ul className={classes.list_container_submenu}>
                {submenuOnlySections.map((submenu, index) => {
                  return (
                    <li key={index} className={classes.menu_item_section}>
                      <a href={submenu.path}>{submenu.name}</a>
                    </li>
                  );
                })}
              </ul>
            )}
            {submenuSectionsWithSubsections.length > 0 && (
              <ul
                className={classes.list_container_submenu_with_subections}
                style={
                  submenuOnlySections.length > 0
                    ? { maxWidth: "50%" }
                    : { maxWidth: "100%" }
                }
              >
                {submenuSectionsWithSubsections.map((submenu, index) => {
                  return (
                    <li key={index} className={classes.menu_item_section}>
                      <a href={submenu.path}>{submenu.name}</a>
                      {submenu.submenu_items.length > 0 && (
                        <ul className={classes.list_container_subsection}>
                          {submenu.submenu_items.map((subsection, index) => {
                            return (
                              <li key={index} className={classes.menu_item}>
                                <a href={subsection.path}>{subsection.name}</a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

TopNavBar.propTypes = {
  townSlug: string,
  displayWeather: bool,
  isWeatherClickable: bool,
  sponsorInfo: object,
  section: object,
  currentUser: object,
  isPathUserSignIn: bool,
};

export default TopNavBar;
