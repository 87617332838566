import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";
const TOWN = "town";
const SECTION = "section";

const validateRecaptcha = async (token) => {
  const response = await restClient.post(
    withConfig("VALIDATE_RECAPTCHA_URL"),
    {
      token,
    },
    false
  );
  return response;
};

const subscribeTo = async (
  contentType,
  contentSlug,
  subscriberEmail,
  token,
  placement,
  multipleTowns,
  update_subscription_towns = false
) => {
  var formData = new FormData();
  formData.append("email", subscriberEmail);
  if (multipleTowns) {
    formData.append("subscribed_town_slugs[]", contentSlug);
  } else {
    if (contentType === TOWN) {
      formData.append("subscribed_town_slugs[]", [contentSlug]);
    }
    if (contentType === SECTION) {
      formData.append("subscribe_section_slugs[]", [contentSlug]);
    }
  }

  formData.append("recaptcha_token", token);
  formData.append("comes_from", placement);
  formData.append("frequency", "daily");

  if(update_subscription_towns){
    formData.append("update_subscription_towns", true);
  }

  const response = await restClient.post(
    withConfig("SUBSCRIBE_URL"),
    formData,
    true
  );
  return response;
};

const sendSignUpForm = async (formPayload) => {
  try {
    let payload = formPayload;

    if (formPayload?.selectedTowns) {
      let parsedPayload = { ...formPayload };
      delete parsedPayload.selectedTowns;
      payload = parsedPayload;
    }

    const response = await restClient.post_serializer(
      withConfig("SUBSCRIBE_URL"),
      payload,
      true
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { validateRecaptcha, subscribeTo, sendSignUpForm };
