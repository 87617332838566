import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    mastheadHeights,
    breakpoints: { down: below, values },
  } = theme;
  return {
    masthead_container: {
      backgroundColor: "black",
      display: "flex",
      height: mastheadHeights.overall_desktop,
      gap: spacing(1),
      flexWrap: "nowrap",
      padding: "0% 2%",
      zIndex: 999,
      [below(values.md)]: {
        height: mastheadHeights.overall_mobile,
        justifyContent: "flex-start",
        padding: 0,
        width: "100%",
      },
    },
    grid_item: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    reduced_height: {
      height: mastheadHeights.reduced_mobile_overall,
      position: "fixed",
      transition: "height 0.3s ease",
    },
    masthead_container_iframe: {
      width: `calc(100% - ${spacing(20)}px)`,
    },
    link: {
      transition: "text-shadow 0.2s ease",
      textShadow: "none",
      "& :hover": {
        color: "white",
        textShadow: "3px 3px 8px white",
        transition: "text-shadow 0.2s ease",
      },
      [below(1160)]: {
        "& span": {
          fontSize: "14px",
        },
      },
    },
    dropdown_arrow: {
      width: spacing(1.25),
      height: spacing(1.25),
      marginLeft: spacing(0.5),
    },
    popover: {
      border: `1px solid ${palette.light.gray}`,
    },
    logo_container: {
      width: "100%",
    },
    logo_slogan: {
      maxWidth: "100%",
      [below(values.md)]: {
        display: "none",
      },
    },
    reduced_logo_slogan: {
      display: "none",
    },
    logo_tapinto: {
      maxWidth: "100%",
      transition: "height 0.2s ease",
    },
    reduced_logo_tapinto: {
      height: spacing(4),
      transition: "height 0.2s ease",
      maxWidth: "100%",
    },
    login_dialog_title: {
      "&.MuiDialogTitle-root": {
        height: 0,
        padding: 0,
      },
    },
    login_close_button: {
      width: spacing(3),
      height: spacing(3),
      position: "absolute",
      right: spacing(2),
      top: spacing(2),
      cursor: "pointer",
    },
    login_popup: {
      marginTop: "0px !important",
      [below(960)]: {
        zIndex: "94036 !important",
      },
    },
  };
});

export { useStyles };
