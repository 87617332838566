import React, { useEffect, useState, memo } from "react";
import clsx from "clsx";
import MasterheadDesktop from "./MasterheadDesktop/MasterheadDesktop";
import MasterheadMobile from "./MasterheadMobile/MasterheadMobile";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import TopNavBar from "../../../TopNavBar/TopNavBar";
import OverallMasterhead from "./Overall/Overall";
import {
  useTownTheme,
  withTownThemeContextProvider,
} from "../../../../context/TownThemeContext";
import SubmitLegalNoticeRequestModal from "../../../Modals/SubmitLegalNoticeRequestModal";
import LegalNoticeRequestThankyouModal from "../../../Modals/LegalNoticeRequestThankyouModal";
import values from "../../../../common/Theme/themes/breakpoints/values";
import Grid from "../../../../common/Grid/Grid";
import { useStyles } from "./Masterhead.style";
import ReducedVersion from "./ReducedVersion/ReducedVersion";
import { useMasthead } from "../../../../hooks/useMasthead";
import {
  useMastheadContext,
  withMastheadContextProvider,
} from "../../../../context/MastheadContext";
import {
  useTown,
  withTownContextProvider,
} from "../../../../context/TownContext";
import MobileSearch from "../../../MobileSearch/MobileSearch";
import { sendMessageToParent } from "../../../../utils/helper";
import { urlToUseOnIframe } from "../../../../utils/halstonMediaHelper";
import withConfig from "../../../../utils/withConfig";
import { bool, object, string } from "prop-types";

const Masterhead = (props) => {
  const {
    is_article_preview,
    is_path_user_sign_in,
    is_iframe,
    current_user,
    display_weather,
    show_permission_form,
    show_thankyou_message,
    town_slug,
    section,
    is_weather_clickable,
    sponsor_info,
    overall_page,
  } = props;

  const { setTownSlug: themeTownSlug } = useTownTheme();
  const { setTownSlug: townSlug } = useTown();
  const { initialize: initializeMastheadContext, openMobileSearch } =
    useMastheadContext();
  const classes = useStyles();
  const [openPermisionModal, setOpenPermisionModal] =
    useState(show_permission_form);
  const [openThankYouModal, setOpenThankYouModal] = useState(
    show_thankyou_message
  );

  const handleClosePopUp = () => {
    setOpenPermisionModal(false);
    setOpenThankYouModal(false);
  };

  const { stickyStyle } = useMasthead();
  const isMobile = screenWidthIsLowerThan(values.md);

  useEffect(() => {
    themeTownSlug(town_slug);
    townSlug(town_slug);

    return () => {
      themeTownSlug(null);
      townSlug(null);
    };
  }, [town_slug]);

  useEffect(() => {
    initializeMastheadContext({
      townSlug: town_slug,
      sectionSlug: section?.slug,
      currentUserEmail: current_user?.email,
      overall: overall_page,
    });
  }, [town_slug, section, current_user, overall_page]);

  useEffect(() => {
    const message = openMobileSearch ? "opened" : "closed";

    sendMessageToParent(
      message,
      urlToUseOnIframe(townSlug, withConfig("HOME_MONGO"))
    );
  }, [openMobileSearch]);

  if ((town_slug || section) && !overall_page) {
    return (
      <>
        <Grid
          container
          className={clsx(classes.masthead_container, classes.no_printing)}
        >
          {isMobile ? (
            <MasterheadMobile
              legalNoticePermissions={current_user?.legal_notice_permissions}
              townSlug={town_slug}
              isArticlePreview={is_article_preview}
              currentUser={current_user}
              isPathUserSignIn={is_path_user_sign_in}
              section={section}
              isIframe={is_iframe}
            />
          ) : (
            <MasterheadDesktop
              isArticlePreview={is_article_preview}
              legalNoticePermissions={current_user?.legal_notice_permissions}
              isPathUserSignIn={is_path_user_sign_in}
              townSlug={town_slug}
              section={section}
              currentUser={current_user}
            />
          )}
          {!is_article_preview && (
            <TopNavBar
              townSlug={town_slug}
              displayWeather={display_weather}
              isWeatherClickable={is_weather_clickable || true}
              sponsorInfo={sponsor_info}
              section={section}
              currentUser={current_user}
              isPathUserSignIn={is_path_user_sign_in}
              isIframe={is_iframe}
            />
          )}
        </Grid>

        {isMobile && <MobileSearch openState={openMobileSearch} />}

        <div className={classes.sticky_masthead} style={stickyStyle}>
          <ReducedVersion
            townSlug={town_slug}
            section={section}
            currentUser={current_user}
            displayWeather={display_weather}
            sponsorInfo={sponsor_info}
            isWeatherClickable={is_weather_clickable || true}
            legalNoticePermissions={current_user?.legal_notice_permissions}
            isPathUserSignIn={is_path_user_sign_in}
          />
        </div>
      </>
    );
  }

  return (
    <OverallMasterhead
      isPathUserSignIn={is_path_user_sign_in}
      isIframe={is_iframe}
    />
  );
};

Masterhead.propTypes = {
  is_article_preview: bool,
  is_path_user_sign_in: bool,
  is_iframe: bool,
  current_user: object,
  display_weather: bool,
  show_permission_form: bool,
  show_thankyou_message: bool,
  town_slug: string,
  section: object,
  is_weather_clickable: bool,
  sponsor_info: object,
  overall_page: bool,
};

export default memo(
  withTownContextProvider(
    withMastheadContextProvider(withTownThemeContextProvider(Masterhead))
  )
);
