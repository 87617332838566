import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareYoutube = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="4" fill="#F44336" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.32 11.56C22.886 10.788 22.415 10.646 21.456 10.592C20.498 10.527 18.089 10.5 16.002 10.5C13.911 10.5 11.501 10.527 10.544 10.591C9.587 10.646 9.115 10.787 8.677 11.56C8.23 12.331 8 13.659 8 15.997V16.005C8 18.333 8.23 19.671 8.677 20.434C9.115 21.206 9.586 21.346 10.543 21.411C11.501 21.467 13.911 21.5 16.002 21.5C18.089 21.5 20.498 21.467 21.457 21.412C22.416 21.347 22.887 21.207 23.321 20.435C23.772 19.672 24 18.334 24 16.006V15.998C24 13.659 23.772 12.331 23.32 11.56Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 19V13L19 16L14 19Z"
        fill="#F44336"
      />
    </svg>
  );
};

export default SquareYoutube;
