import { color } from "@uiw/react-color";
import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, up: above, values },
  } = theme;
  return {
    admin_link: {
      "& span": {
        fontWeight: "400 !important",
        textDecoration: "none",
        fontSize: "14px",
        "&:hover": {
          textDecoration: "underline!important",
        },
      },
    },
    masterhead_desktop_container: {
      height: spacing(15),
      width: "100%",
      marginTop: 0,
      display: "flex",
      alignItems: "center",
      height: "fit-content",
      flexDirection: "row",
      flexGrow: 1,
      zIndex: 999,
      "&.MuiGrid-spacing-xs-1": {
        margin: 0,
        width: "100%",
      },
      [`@media print`]: {
        display: window.location.href.includes("legal_notices")
          ? "none"
          : "flex",
        position: "static",
        marginBottom: spacing(2),
      },
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    grid_item: {
      "& .MuiGrid-item": {
        width: "100% !important",
      },
      [below(values.lg)]: {
        padding: "0px !important",
      },
    },
    custom_button_login: {
      height: "fit-content !important",
      margin: "0px !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      height: "fit-content !important",
      padding: `${spacing(1)}px !important`,
      [below(values.lg)]: {
        fontSize: "10px !important",
        lineHeight: "12px !important",
      },
    },
    social_media_container: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    social_media_icon: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: `${spacing(0.5)}px !important`,
    },
    licenses_menu_container: {
      padding: `${spacing(3)}px ${spacing(8)}px`,
      gap: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logged_container: {
      "& span": {
        textDecoration: "none",
        fontSize: "14px",
        "&:hover": {
          textDecoration: "underline!important",
        },
      },
    },
    search_text_field: {
      borderRadius: "4px",
      width: "90% !important",
      height: spacing(4),
      "& input.MuiOutlinedInput-input": {
        fontSize: "12px !important",
      },
    },
    textfield_search_icon: {
      width: spacing(1.5),
      height: spacing(1.5),
    },
    logo_container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    signup: {
      width: "fit-content !important",
      margin: "0px !important",
      height: "fit-content !important",
      textTransform: "uppercase !important",
      padding: `${spacing(1)}px !important`,
      "& .MuiButton-label": {
        fontWeight: "500 !important",
        fontSize: "12px !important",
      },
      [above(values.lg)]: {
        padding: `${spacing(1)}px ${spacing(3)}px !important`,
      },
    },
    right_buttons_container: {
      gap: theme.spacing(2),
      "& div > button": {
        borderRadius: `${spacing(1)}px !important`,
      },
    },
  };
});

export { useStyles };
