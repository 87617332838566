import React, { useReducer, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../../../common/Button/Button";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  subscribeToEnews,
  subscribeToEnewsInitialState,
} from "../../../reducers/forms/formReducers";
import { sendSignUpForm } from "../../../services/subscriber";
import labels from "../../../config/labels";
import TextField from "../../../common/TextField/TextField";
import { useStyles } from "./Form.style";
import AddMoreTowns from "../AddMoreTowns/AddMoreTowns";
import { fetchSubscriptions } from "../../../services/users";
import { insertCurrentTownToSubscripitonPayload } from "../../../utils/helpers/townsHelper";
import SignUpBottomText from "../../SignUpNewsletter/SignUpBottomText/SignUpBottomText";

const ACTION = "homepageSignUp";

const Form = ({
  loadingContent,
  addError,
  handleSuccess,
  townToUse,
  emailAddress,
}) => {
  const classes = useStyles();
  const town = townToUse;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState(emailAddress);
  const [selectSettings, setSelectSettings] = useState({
    visible: false,
    options: [],
  });
  const [formData, setFormData] = useReducer(
    subscribeToEnews,
    subscribeToEnewsInitialState
  );
  const [addMoreTownsShow, setAddMoreTownsShow] = useState(false);

  const fetchUserSubscriptions = async () => {
    try {
      const subscriptions = await fetchSubscriptions(formData?.email);

      if (
        subscriptions?.status === 200 &&
        subscriptions?.data?.previous_subscriptions
      ) {
        return subscriptions?.data?.previous_subscriptions;
      } else {
        addError({
          key: "fetchUserSubscriptions",
          message: labels.ERROR_SUBSCRIPTIONS,
        });
        return [];
      }
    } catch (error) {
      throw error;
    }
  };

  const modifySelectSettings = (value) => {
    setSelectSettings(value);
  };

  const handleFormFieldChange = (value) => {
    if (value.email) {
      modifySelectSettings({
        ...selectSettings,
        visible: false,
      });
    }
    setFormData(value);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const responseToken = await executeRecaptcha(ACTION);
    setFormData({
      recaptcha_token: responseToken,
    });
  }, [executeRecaptcha]);

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();

    loadingContent(true);
    const subscriptionError = {
      key: "sendSignUpForm",
      message: labels.SIGNUP_ERROR,
    };

    const alreadySubscribedError = {
      key: "sendSignUpForm",
      message: labels.ALREADY_SUBSCRIBED,
    };

    if (selectSettings.visible && formData.selectedTowns.length === 0) {
      addError({
        key: "empty-towns",
        message: labels.SELECT_AT_LEAST_ONE_TOWN,
      });
      loadingContent(false);
      return;
    }

    const payload = insertCurrentTownToSubscripitonPayload({
      previousSubscriptions: await fetchUserSubscriptions(),
      town: town,
      formData: formData,
    });

    if(addMoreTownsShow){
      payload.update_subscription_towns = true
    }

    let signUpResponse = await sendSignUpForm(payload);

    if (signUpResponse?.status === 200) {
      const responseMessage = signUpResponse.data.message;
      if (
        responseMessage &&
        (responseMessage.includes("update") ||
          responseMessage.includes("new-user"))
      ) {
        handleSuccess(responseMessage);
      } else {
        addError(subscriptionError);
      }
    } else {
      if (signUpResponse?.status === 204) {
        addError(alreadySubscribedError);
      } else {
        addError(subscriptionError);
      }
    }

    loadingContent(false);
  };

  useEffect(() => {
    if (email) {
      handleFormFieldChange({ email });
    }
  }, [email]);

  return (
    <form onSubmit={(e) => handleSubmitSubscription(e)}>
      <AddMoreTowns
        formData={formData}
        handleTownSelect={handleFormFieldChange}
        loadingContent={loadingContent}
        fetchUserSubscriptions={fetchUserSubscriptions}
        selectSettings={selectSettings}
        modifySelectSettings={modifySelectSettings}
        town={town}
        setAddMoreTownsShow={setAddMoreTownsShow}
      />

      <TextField
        type="email"
        placeholder="subscriber@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />

      <Button
        type="submit"
        level="simple_button"
        color="primary"
        children={labels.SIGN_UP}
        classes={{
          root: clsx(
            classes.button,
            classes.button_label,
            classes.sign_up_button
          ),
        }}
        ariaLabel="submit subscription"
        id="newsletter-subscription-sidebar"
      />
      <SignUpBottomText town={town} withDisclaimer={true} />
    </form>
  );
};

Form.propTypes = {
  loadingContent: PropTypes.func,
  addError: PropTypes.func,
  handleSuccess: PropTypes.func,
  emailAddress: PropTypes.string,
};

export default Form;
