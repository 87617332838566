import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import OneCard from "./ContentCards/OneCard/OneCard";
import TwoCards from "./ContentCards/TwoCards/TwoCards";
import ThreeCards from "./ContentCards/ThreeCards/ThreeCards";
import { useStyles } from "./Contents.style";
import {
  REAL_ESTATE_BLOCK,
  EVENTS_BLOCK,
  OBITUARIES_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import RealEstateCard from "../../../ContentCard/RealEstateCard/RealEstateCard";
import EventCard from "../../../ContentCard/EventCard/EventCard";
import ObituaryCard from "../../../ContentCard/ObituaryCard/ObituaryCard";
import AdCard from "../../../Homepage/AdCard/AdCard";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import FourCards from "./ContentCards/FourCards/FourCards";

const Contents = ({ contents, block, adProps, town = null }) => {
  const adUnits = screenWidthIsLowerThan(768)
    ? adProps?.mobile_ad_units
    : adProps?.desktop_ad_units;
  const singleAd = adUnits?.length === 1;
  const isFourCards = block?.contents?.length === 4;
  const classes = useStyles({ singleAd, isFourCards });

  if (adProps) {
    return (
      <div className={classes.ads_container}>
        {adUnits?.map((adUnit, index) => (
          <Fragment key={"advertisement-" + index}>
            <AdCard
              adTargets={adProps?.ad_targets}
              adUnit={adUnit}
              adSize={adProps?.size}
            />
          </Fragment>
        ))}
      </div>
    );
  }

  if (contents?.length > 0) {
    if (contents?.length > 1) {
      switch (block?.class) {
        case REAL_ESTATE_BLOCK:
          return (
            <div className={classes.cards_container}>
              {contents.map((content, index) => (
                <Fragment key={`real-estate-card-${index}`}>
                  <RealEstateCard
                    content={content}
                    options={{
                      withShadow: false,
                      forHomepage: true,
                      contentsLength: contents.length,
                      isFourCardRealEstate: isFourCards,
                    }}
                    customClasses={{
                      carousel: {
                        image: clsx({
                          [classes.double_real_estate_carousel_image]:
                            contents.length === 2,
                          [classes.triple_real_estate_carousel_image]:
                            contents.length !== 2,
                          [classes.real_estate_image_container]: isFourCards,
                          [classes.real_estate_image_container_four_cards]:
                            isFourCards,
                        }),
                        container: clsx({
                          [classes.double_real_estate_carousel_container]:
                            contents.length === 2,
                          [classes.triple_real_estate_carousel_container]:
                            contents.length !== 2,
                          [classes.real_estate_carousel_image_four_cards]:
                            isFourCards,
                        }),
                      },
                    }}
                  />
                </Fragment>
              ))}
            </div>
          );

        case EVENTS_BLOCK:
          return (
            <div className={classes.cards_container}>
              {contents.map((content, index) => (
                <Fragment key={"event-card-" + index}>
                  <EventCard
                    customClasses={{ card: classes.event_card }}
                    content={content}
                    options={{
                      withShadow: false,
                      forHomepage: true,
                      contentsLength: contents.length,
                      isFourCards: isFourCards ? true : false,
                    }}
                  />
                </Fragment>
              ))}
            </div>
          );

        case OBITUARIES_BLOCK:
          return (
            <div className={classes.cards_container}>
              {contents.map((content, index) => (
                <Fragment key={"obituary-card-" + index}>
                  <ObituaryCard
                    content={content}
                    options={{
                      forHomepage: true,
                      contentsLength: contents.length,
                      withShadow: false,
                    }}
                  />
                </Fragment>
              ))}
            </div>
          );

        default:
          break;
      }
    }

    switch (contents.length) {
      case 1:
        return block?.class == EVENTS_BLOCK && screenWidthIsLowerThan(768) ? (
          <div className={classes.cards_container}>
            {contents.map((content, index) => (
              <Fragment key={"event-card-" + index}>
                <EventCard
                  content={content}
                  options={{
                    withShadow: false,
                    forHomepage: true,
                    contentsLength: contents.length,
                  }}
                />
              </Fragment>
            ))}
          </div>
        ) : block?.class == OBITUARIES_BLOCK && screenWidthIsLowerThan(768) ? (
          <div className={classes.cards_container}>
            {contents.map((content, index) => (
              <Fragment key={"obituary-card-" + index}>
                <ObituaryCard
                  content={content}
                  options={{
                    forHomepage: true,
                    contentsLength: contents.length,
                    withShadow: false,
                  }}
                />
              </Fragment>
            ))}
          </div>
        ) : (
          <div className={classes.one_card_container}>
            <div>
              <OneCard
                contents={contents}
                blockClass={block?.class}
                block={block}
                contentTown={town}
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className={clsx(classes.two_or_more_cards_container)}>
            <div>
              <TwoCards contents={contents} blockClass={block?.class} />
            </div>
          </div>
        );

      case 4:
        return (
          <div className={clsx(classes.two_or_more_cards_container)}>
            <FourCards
              contents={contents}
              blockClass={block?.class}
              town={town}
            />
          </div>
        );

      default:
        return (
          <div className={clsx(classes.two_or_more_cards_container)}>
            <div>
              <ThreeCards contents={contents} blockClass={block?.class} contentTown={town} />
            </div>
          </div>
        );
    }
  }

  return <></>;
};

Contents.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object),
  haveSponsors: PropTypes.object,
  adProps: PropTypes.shape({
    id: PropTypes.number,
    class: PropTypes.string,
    side: PropTypes.string,
    show_mobile: PropTypes.bool,
    show_desktop: PropTypes.bool,
    ad_type: PropTypes.string,
    size: PropTypes.arrayOf(PropTypes.number),
    desktop_ad_units: PropTypes.arrayOf(PropTypes.string),
    mobile_ad_units: PropTypes.arrayOf(PropTypes.string),
    ad_targets: PropTypes.oneOfType([
      PropTypes.shape({
        towns: PropTypes.arrayOf(PropTypes.string),
        sections: PropTypes.arrayOf(PropTypes.string),
        sponsor_display: PropTypes.arrayOf(PropTypes.string),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          towns: PropTypes.arrayOf(PropTypes.string),
          sections: PropTypes.arrayOf(PropTypes.string),
          sponsor_display: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    ]),
  }),
};

export default Contents;
