import React, { useState } from "react";
import { bool, object, string } from "prop-types";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import { useStyles } from "./MasterheadDesktop.style";
import MasterheadLogo from "../MasterheadLogo";
import Editor from "../../../../Editor/Editor";
import SubmitContentModal from "../../../../Modals/SubmitContentModal/SubmitContentModal";
import PreviewBanner from "../PreviewBanner/PreviewBanner";
import withConfig from "../../../../../utils/withConfig";
import SavedDraftsModal from "../../../../Modals/SavedDraftsModal/SavedDraftsModal";
import CustomButtons from "./CustomButtons/CustomButtons";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import Grid from "../../../../../common/Grid/Grid";
import Link from "../../../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useMastheadContext } from "../../../../../context/MastheadContext";

const MasterheadDesktop = ({
  isArticlePreview,
  legalNoticePermissions,
  isPathUserSignIn,
  townSlug,
  section,
  currentUser,
}) => {
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const [openSavedDraftsPopUp, setOpenSavedDraftsPopUp] = useState(false);
  const classes = useStyles();
  const { themeColors, town } = useTownTheme();
  const { mastheadInfo, adminMenuItems, townsWithClusters } =
    useMastheadContext();
  const adminLinkLevel = screenWidthIsLowerThan(values.lg)
    ? "t4_text_4"
    : "paragraph_1";

  const itemColumns = (itemsLength, index) => {
    if (itemsLength % 2 === 0) {
      return 6;
    } else {
      if (index === itemsLength - 1) {
        return 12;
      }
      return 6;
    }
  };

  return (
    <>
      {isArticlePreview && <PreviewBanner />}

      <Grid
        style={{
          backgroundColor: themeColors?.masthead_colors?.background,
          marginTop: isArticlePreview ? "50px" : "0px",
        }}
        className={clsx(
          classes.masterhead_desktop_container,
          classes.no_printing,
          isArticlePreview && classes.margin_top_50
        )}
        justifyContent="space-evenly"
        spacing={1}
        container
      >
        <Grid xs={3} item className={classes.grid_item}>
          <Editor editor={mastheadInfo?.editor} />
        </Grid>

        <Grid
          xs={4}
          item
          className={clsx(classes.grid_item, classes.logo_container)}
        >
          <MasterheadLogo
            envUrl={withConfig("HOME")}
            townName={town?.name || section?.name}
            logoUrl={mastheadInfo?.logo_image}
            townSlug={town?.slug}
            sectionSlug={section?.slug}
            isLicense={town?.under_license_contract}
          />
        </Grid>

        {currentUser === null && (
          <Grid xs={3} item className={classes.grid_item}>
            <CustomButtons
              townsWithClusters={townsWithClusters}
              isPathUserSignIn={isPathUserSignIn}
            />
          </Grid>
        )}

        {currentUser !== null && currentUser?.email && (
          <Grid className={classes.grid_item} xs={3} item>
            <Grid container spacing={1}>
              <Grid
                item
                className={classes.admin_link}
                xs={itemColumns(adminMenuItems.length + 2, 0)}
              >
                <Link
                  children={labels.SUBMIT_CONTENT}
                  color="white"
                  level={adminLinkLevel}
                  underlined
                  onClick={() => setOpenSubmitContentPopUp(true)}
                />
              </Grid>

              <Grid
                item
                className={classes.admin_link}
                xs={itemColumns(adminMenuItems.length + 2, 1)}
              >
                <Link
                  url={withConfig("SIGN_OUT")}
                  children={labels.LOGOUT}
                  color="white"
                  level={adminLinkLevel}
                  underlined
                />
              </Grid>

              {adminMenuItems?.map((item, index) => (
                <Grid
                  key={`admin-menu-item-${index}`}
                  item
                  className={classes.admin_link}
                  xs={itemColumns(adminMenuItems.length + 2, index + 2)}
                >
                  <Link
                    {...(item.code != "drafts" && { url: item.path })}
                    {...(item.code === "drafts" && {
                      onClick: () => setOpenSavedDraftsPopUp(true),
                    })}
                    children={item.label}
                    color="white"
                    level={adminLinkLevel}
                    underlined
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>

      <SavedDraftsModal
        handleClose={() => setOpenSavedDraftsPopUp(false)}
        openState={openSavedDraftsPopUp}
        currentUserId={currentUser?.id}
      />

      <SubmitContentModal
        town={{ id: townSlug }}
        currentUserId={currentUser?.email}
        legalNoticePermissions={legalNoticePermissions}
        handleClose={() => setOpenSubmitContentPopUp(false)}
        openState={openSubmitContentPopUp}
      />
    </>
  );
};

MasterheadDesktop.propTypes = {
  isArticlePreview: bool,
  legalNoticePermissions: object,
  isPathUserSignIn: bool,
  townSlug: string,
  section: object,
  currentUser: object,
};

export default MasterheadDesktop;
