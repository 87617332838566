import React, { useEffect, useState } from "react";
import BubbledButton from "../../../../../../../common/Button/BubbledButton";
import { redirectToPage } from "../../../../../../../utils/helper";

const NewsletterSignupButton = ({ button, className, signUpPath }) => {
  const [path, setPath] = useState(null);
  useEffect(() => {
    if (button?.action && button?.action != "default_newsletter_signup") {
      setPath(button.action);
    }
  }, [button]);
  return (
    <BubbledButton
      level="custom_button"
      backgroundColor={button.background_color}
      labelColor={button.label_color}
      className={className}
      onClick={() => redirectToPage(path ? path : signUpPath, "_top")}
    >
      {button.label}
    </BubbledButton>
  );
};

export default NewsletterSignupButton;
