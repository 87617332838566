import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

export const getUserContributions = async (userSlug) => {
  try {
    const response = await restClient.get(
      withConfig("GET_USER_CONTRIBUTIONS"),
      {
        user_slug: userSlug,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const fetchSubscriptions = async (email) => {
  try {
    const response = await restClient.get(withConfig("FETCH_SUBSCRIPTIONS"), {
      email: email,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (formData) => {
  try {
    const response = await restClient.get(withConfig("RESET_USER_PASSWORD"), {
      ...formData,
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchLegalNoticeEntities = async (userId) => {
  try {
    const response = await restClient.get(
      withConfig("FETCH_LEGAL_NOTICE_ENTITIES"),
      {
        user_id: userId,
      }
    );
    return response;
  } catch (error) {
    throw e;
  }
};

export const fetchUserSaleFilesTownsFn = async ({ userId, saleFileIds }) => {
  const response = await restClient.get(withConfig("GET_USER_SALE_FILES_TOWNS"), {
    user_id: userId,
    sale_file_ids: saleFileIds,
  });
  return response;
};

export const fetchUserSaleFilesFn = async (userId) => {
  const response = await restClient.get(withConfig("GET_USER_SALE_FILES"), {
    user_id: userId,
  });
  return response;
};

export const fetctSelectedUserFreeTownsFn = async ({ userId }) => {
  const response = await restClient.get(
    withConfig("GET_SELECTED_USER_FREE_TOWNS"),
    {
      user_id: userId,
    }
  );
  return response;
};

export const fetchSelectedUserFreeTownsForLegalNoticeFn = async ({ userId }) => {
  const response = await restClient.get(
    withConfig("GET_SELECTED_USER_FREE_TOWNS_FOR_LEGAL_NOTICE"),
    {
      user_id: userId,
    }
  );
  return response;
};

export const setUserSelectedFreeTownsAndSaleFilesFn = async ({
  userId,
  selectedFreeTowns,
  selectedFreeTownsForLegalNotice,
  selectedSaleFiles,
  facebookFree,
}) => {
  const response = await restClient.post(
    withConfig("SET_USER_SELECTED_FREE_TOWNS_AND_SALE_FILES"),
    {
      user_id: userId,
      free_town_ids: selectedFreeTowns,
      free_town_ids_for_legal_notice: selectedFreeTownsForLegalNotice,
      sale_file_ids: selectedSaleFiles,
      advertiser_fb_free: facebookFree,
    }
  );
  return response;
}