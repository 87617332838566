import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareTwitterBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="2" fill="white" />
      <g clip-path="url(#clip0_2828_2436)">
        <path
          d="M9.03524 9.33337L14.4412 16.6874L9 22.6667H10.224L14.9862 17.4309L18.8345 22.6667H23L17.291 14.8991L22.3539 9.33337H21.1299L16.7436 14.1556L13.2007 9.33337H9.03524ZM10.8349 10.2514H12.7496L21.2004 21.751H19.2856L10.8349 10.2514Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2828_2436">
          <rect
            width="14"
            height="13.3333"
            fill="white"
            transform="translate(9 9.33337)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTwitterBlack;
