import React, { useEffect, useState } from "react";
import Masterhead from "../LayoutsByVariant/Default/Masterhead";
import MasterheadLayout from "../LayoutsByVariant/Layout1/Masterhead";
import { useTown } from "../../../context/TownContext";

const Layouts = (props) => {
  const { masthead_template } = props;
  const [template, setTemplate] = useState();
  const { town, templateMasterhead, loadMasterheadTemplate } = useTown();

  useEffect(() => {
    if (town) {
      loadMasterheadTemplate(town);
    }
    return () => {};
  }, [town]);

  useEffect(() => {
    if (masthead_template) {
      setTemplate(masthead_template);
    } else if (templateMasterhead) {
      setTemplate(templateMasterhead);
    } else {
      setTemplate("default");
    }
  }, [templateMasterhead]);

  switch (template) {
    case "default":
      return <Masterhead {...props} />;
    case "layout_1":
      return <MasterheadLayout {...props} />;
    default:
      return <Masterhead {...props} />;
  }
};

export default Layouts;
