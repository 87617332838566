import React from "react";
import Box from "../../common/Box/Box";
import { Button } from "@material-ui/core";
import {
  CELSIUS_UNIT,
  FARENTHEIT_UNIT,
  ICONS,
  IMPERIAL_WEATHER_SYSTEM,
} from "./Weather";
import Typography from "../../common/Typography/Typography";
import Link from "../../common/Link/Link";
import useStyles from "./Weather.style";

export const WeatherTooltipLicensesCard = ({ weather, weatherSystem }) => {
  const CurrentWeatherIcon = ICONS[weather.forecast.current_conditions?.Icon];
  const NightlyConditionsIcon =
    ICONS[weather.forecast.nightly_conditions?.Icon];
  const TomorrowConditionsIcon =
    ICONS[weather.forecast.tomorrow_conditions?.Icon];

  const classes = useStyles();

  return (
    <>
      <Box className={classes.weather_title_container_licenses}>
        <Box className={classes.tooltip_title_header_licenses}>
          <Typography level="t4_text_4" color="white">
            {weather.town_name}, {weather.forecast.country_id}
          </Typography>
        </Box>
        <Link
          level="t0_text_0"
          url={weather.forecast.current_conditions_link}
          target="__blank"
        >
          <Box className={classes.flex_container_column_center_no_gap}>
            <Box className={classes.flex_container}>
              <CurrentWeatherIcon />
              <Typography level="font_size_32_px" color="white" bold>
                {weather.forecast.current_conditions?.[weatherSystem]?.Value}
              </Typography>
              <Typography level="font_size_32_px" color="white">
                °
              </Typography>
              <Typography level="h4" color="white">
                {weather.forecast.current_conditions?.[weatherSystem]?.Unit}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.weather_title_content_licenses}>
            <Box className={classes.flex_container_column_center}>
              <Box className={classes.flex_container}>
                <NightlyConditionsIcon size="sm" />
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Typography level="medium_20_px" color="white" bold>
                      {
                        weather.forecast.nightly_conditions?.[weatherSystem]
                          ?.Value
                      }
                    </Typography>
                    <Box
                      className={classes.flex_container_column_center_no_gap}
                    >
                      <Typography level="t0_text_0" color="white">
                        °
                      </Typography>
                      <Typography level="tiny" color="white">
                        {
                          weather.forecast.nightly_conditions?.[weatherSystem]
                            ?.Unit
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Typography level="t0_text_0" color="white">
                    Tonight
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.flex_container_column_center}>
              <Box className={classes.flex_container}>
                <TomorrowConditionsIcon size="sm" />
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Typography level="medium_20_px" color="white" bold>
                      {
                        weather.forecast.tomorrow_conditions?.[weatherSystem]
                          ?.Value
                      }
                    </Typography>
                    <Box
                      className={classes.flex_container_column_center_no_gap}
                    >
                      <Typography level="t0_text_0" color="white">
                        °
                      </Typography>
                      <Typography level="tiny" color="white">
                        {
                          weather.forecast.tomorrow_conditions?.[weatherSystem]
                            ?.Unit
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Typography level="t0_text_0" color="white">
                    Tomorrow
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Link>
        <Box className={classes.weather_title_content_footer_licenses}>
          <Box className={classes.weather_content_footer}>
            <Link
              level="t0_text_0"
              url={weather.forecast.current_conditions_link}
              target="__blank"
              color="white"
            >
              See more weather info at AccuWeather.com
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};
