const pdfUploaderLabels = {
  ACTIONS: "Actions",
  PDF_ACTIONS: "PDF Actions",
  CREDITS_AND_CAPTIONS: "Credits & Captions",
  COPY_THUMBNAIL_URL: "Copy Thumbnail url to clipboard",
  ADD_PDF_TO_IMAGES_CAROUSEL: "Add PDF to images carousel",
  SHOW_ON_CAROUSEL: "Show on carousel?",
  PREVIEW: "Preview",
  COPY: "Copy",
  CREDITS: "Credits",
  MISSING_CREDITS: "Credits should not be empty",
  CAPTION: "Caption",
  CAPTION_LIMIT: "(No more than 600 characters)",
  SUCCESSFULLY_COPIED_TO_CLIPBOARD: "Successfully copied to clipboard",
  PDF_ACTION_LABELS: {
    NEXT_STEP: "Next Step",
    SELECT_PDF_PAGE_NUMBER: "Select PDF page number for thumbnail",
    REMOVE: "Remove",
    REMOVAL_CONFIRMATION: "Are you sure you want to remove the document(s)?",
    COPY_PDF_URL: "Copy the PDF url and then hyperlink your text",
    COPY_HTML_FOR_PDF:
      "Copy the PDF HTML and then use the HTML button on the text editor",
    YOU_CAN_EMBED_THIS_PDF_IN_THE_TEXT:
      "You can also embed this PDF in the text",
  },
  SUPPORTED_FILE_TYPES: "Supported file types: ",
  WARNING: "WARNING:",
  DOCUMENTS_NAME_WARNING:
    "Please note that your file names will be visible to the public. Be sure to name them appropriately.",
  FILE_NAME_TEXT:
    "File name cannot contain special characters or spaces. Only hyphens (-) and underscores (_) are allowed.",
};

export default pdfUploaderLabels;
