/*eslint sort-keys: "error"*/

const enewsSignupLabels = {
  BEFORE_YOU_GO: "Before you go!",
  EMAIL_ADDRESS: "Email Address",
  SIGN_UP: "Sign Up",
  SIGN_UP_FOR_FREE_E_NEWS: "Sign Up for Free E-News",
  SIGNUP_FOR_FREE_MESSAGE:
    "It's great to stay connected. <b>Sign up</b> for <b>FREE</b> to TAPinto ##town and be the first to know everything about your community.",
  SIGN_UP_CLAUSE_TEXT:
    "By signing up, you agree to TAPinto's TAPINTO_TERMS_OF_USE and TAPINTO_PRIVACY_POLICY. ",
  SIGN_UP_BOX_HEADER:
    "Get TOWN local news you can trust in your inbox for FREE",
  SIGN_UP_OVERALL:
    "Get the latest news from your town sent directly to your inbox.",
  ADD_MORE_TOWNS: "+ Add more towns",
  SUBSCRIBED_IN_TOWNS_MESSAGE: "TAPinto site(s) you would like to receive e-news from",
  VALID_EMAIL_REQUIRED_TO_ADD_MORE_TOWNS:
    "A valid email is required to add more towns",
  ALREADY_SUBSCRIBED: "Your email address is already subscribed",
  MODIFY_SUBSCRIPTION: "Please modify your subscription if required",
  ALREADY_SUBSCRIBED_CLICK_HERE:
    "Your email address is already subscribed. To modify the subscription, please click ",
};
export default enewsSignupLabels;
