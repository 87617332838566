import React from "react";
import { ICONS_SIZES } from "../Weather";

const SquareBlueskyBlack = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <rect width="32" height="32" rx="2" fill="white" />
      <path
        d="M11.5108 9.94232C13.3277 11.311 15.2824 14.0859 15.9998 15.575V19.508C15.9998 19.4243 15.9675 19.519 15.8983 19.7227C15.5238 20.8262 14.0611 25.1329 10.7168 21.69C8.95582 19.8773 9.77106 18.0645 12.9766 17.5172C11.1427 17.8302 9.08112 17.3129 8.51551 15.2847C8.35255 14.7012 8.0752 11.1075 8.0752 10.6221C8.0752 8.19074 10.1997 8.95497 11.5108 9.94232ZM20.4888 9.94232C18.6719 11.311 16.7172 14.0859 15.9998 15.575V19.508C15.9998 19.4243 16.0319 19.519 16.1013 19.7227C16.4757 20.8262 17.9383 25.1329 21.2827 21.69C23.0437 19.8773 22.2285 18.0645 19.023 17.5172C20.8567 17.8302 22.9184 17.3129 23.484 15.2847C23.647 14.7012 23.9244 11.1075 23.9244 10.6221C23.9244 8.19074 21.8001 8.95497 20.4888 9.94232Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareBlueskyBlack;
