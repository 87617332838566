import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    main_container: {
      display: "grid",
      alignItems: "center",
      paddingInline: spacing(22),
      gap: spacing(3),
    },
    modal_title: {
      backgroundColor: palette.common.white,
    },
    content_title: {
      fontWeight: 800,
      textAlign: "center",
    },
    content_warning: {
      fontWeight: 400,
      textAlign: "center",
      color: palette.dark.gray,
    },
    inputs_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: spacing(3),
    },
    text_field: {
      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        borderColor: "transparent !important",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.dark.gray,
        backgroundColor: theme.palette.miscellaneous.white,
      },
    },
    add_more_entities_button: {
      display: "grid",
      justifyContent: "start",
      "& button": {
        padding: `${spacing(1)}px ${spacing(2)}px`,
        fontFamily: "Montserrat",
        textTransform: "none",
        backgroundColor: alpha(palette.themeColor.primary, 0.5),
        color: palette.dark.black + " !important",
        fontSize: "15px !important",
        "&:hover": {
          backgroundColor: alpha(palette.themeColor.primary, 0.8),
        },
      },
    },
    actions_container: {
      display: "flex",
      justifyContent: "center",
      gap: spacing(2),
      marginBottom: spacing(5),
    },
    cancel_button: {
      fontFamily: "Montserrat !important",
      border: `1px solid ${palette.dark.black} !important`,
      textTransform: "none !important",
      fontSize: "16px !important",
    },
    request_button: {
      fontFamily: "Montserrat !important",
      backgroundColor: palette.themeColor.primary + " !important",
      textTransform: "none !important",
      fontSize: "16px !important",
      color: palette.common.white + " !important",
      minWidth: "150px !important",
    },
    request_by_po_checkbox: {
      fontFamily: "Montserrat !important",
    },
    choose_button: {
      border: `2px solid ${theme.palette.themeColor.secondary} !important`,
      borderRadius: `${spacing(1)}px !important`,
      "& .MuiButton-label": {
        color: theme.palette.dark.black + " !important",
      },
    },
    states_select: {
      height: spacing(7),
      borderRadius: spacing(0.5),
      margin: "0",
    },
    danger_border: {
      border: `0.4px solid ${palette.dark.red} !important`,
    },
    helperText: {
      paddingLeft: spacing(1.75),
      fontWeight: 500,
      fontSize: "12px",
      color: `${palette.dark.red} !important`,
    },
  };
});

export { useStyles };
