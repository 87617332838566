import React, { useEffect, useState } from "react";
import TownPicker from "../../TownPicker/TownPicker";
import Location from "../../../common/Icons/Location";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import { useStyles } from "./TownPickerWithNearbyTowns.style";
import { getTownsAsItems } from "../../../services/townService";
import withConfig from "../../../utils/withConfig";
import Tooltip from "../../../common/Tooltip/Tooltip";
import theme from "../../../common/Theme/themes/default";
import { array, func, object } from "prop-types";
import NearbyTowns from "../../TownPicker/NearbyTowns/NearbyTowns";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { sendMessageToParent } from "../../../utils/helper";
import { urlToUseOnIframe } from "../../../utils/halstonMediaHelper";

const OPENED = "opened-desktop";
const CLOSED = "closed-desktop";

const TownPickerWithNearbyTowns = ({
  nearbyTowns,
  towns,
  town,
  handleComboboxForIframe,
}) => {
  const classes = useStyles();
  const [openNearbyTownsPopUp, setOpenNearbyTownsPopUp] = useState(false);

  const handleNearbyTownsClick = () => {
    setOpenNearbyTownsPopUp(true);
  };

  useEffect(() => {
    const message = openNearbyTownsPopUp ? OPENED : CLOSED;
    sendMessageToParent(
      message,
      urlToUseOnIframe(town?.slug, withConfig("HOME_MONGO"))
    );
  }, [openNearbyTownsPopUp]);

  return (
    <>
      <TownPicker
        containerClassName={classes.townpicker_container}
        comboBoxClassName={classes.townpicker}
        isMultipleWithTags={false}
        townsAsItems={getTownsAsItems(towns, withConfig("HOME"))}
        placeholder={town?.options?.town_picker?.placeholder}
        handleOpen={handleComboboxForIframe}
        handleClose={handleComboboxForIframe}
        withNoTownOption={false}
        {...(nearbyTowns?.length === 0 && {
          startIcon: (
            <Location
              fillColor="gray"
              className={classes.textfield_search_icon}
            />
          ),
        })}
      />
    </>
  );
};

TownPickerWithNearbyTowns.propTypes = {
  nearbyTowns: array,
  towns: array,
  town: object,
  handleComboboxForIframe: func,
};

export default TownPickerWithNearbyTowns;
